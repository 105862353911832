"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("section", {
    staticClass: "section hero is-fullheight is-error-section"
  }, [_c("div", {
    staticClass: "hero-body"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "columns is-centered"
  }, [_c("div", {
    staticClass: "column is-two-fifths"
  }, [_c("router-view")], 1)])])]), _vm._v(" "), _vm._m(0)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "hero-foot has-text-centered"
  }, [_c("div", {
    staticClass: "logo"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;