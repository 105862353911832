"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-dashboard-details-service"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n          Serviços ativos\n        ")])])])]), _vm._v(" "), _c("tiles", [_c("div", {
    staticClass: "card-filters"
  }, [_c("h3", [_vm._v("Período:")]), _vm._v(" "), _c("div", {
    staticClass: "content-filters"
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("b-datepicker", {
    attrs: {
      locale: "pt-BR",
      placeholder: "Selecione o périodo",
      "mobile-native": false,
      range: ""
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1), _vm._v(" "), _c("b-button", {
    attrs: {
      "icon-right": "magnify"
    },
    on: {
      click: _vm.updateDataItems
    }
  }, [_vm._v("\n          Filtrar\n        ")])], 1)])]), _vm._v(" "), _c("tiles", {
    attrs: {
      "max-per-row": 4
    }
  }, [_c("b-tooltip", {
    staticClass: "tooltip-service",
    attrs: {
      label: _vm.servicesName,
      position: "is-bottom",
      "is-multiline": ""
    }
  }, [_c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-primary",
      number: _vm.details.TotalServicesNow || 0,
      label: "Serviços chamados"
    }
  })], 1), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-success",
      number: _vm.details.TotalFetchs || 0,
      label: "Total de requisições"
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.details.TotalNotFetchs || 0,
      label: "Total de requisições com erros"
    }
  })], 1), _vm._v(" "), _c("card-component", {
    attrs: {
      title: _vm.titleMonitoramento,
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.fillChartData
    }
  }, [_vm.chartData ? _c("div", {
    staticClass: "chart-area"
  }, [_c("line-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartData,
      "chart-options": _vm.chartOptions
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Última requisição por serviço",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.getDetailsList
    }
  }, [_c("table-services-actives-calls-list", {
    attrs: {
      list: _vm.list
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Quantidade de requisições com sucesso"
    }
  }, [_vm.chartRequestsMonetizablesTotal ? _c("doughnut-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartRequestsMonetizablesTotal
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Quantidade de requisições totais"
    }
  }, [_vm.chartRequestsTotal ? _c("doughnut-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartRequestsTotal
    }
  }) : _vm._e()], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Quantidade de requisições com Erro"
    }
  }, [_vm.chartRequestsErrorTotal ? _c("doughnut-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartRequestsErrorTotal
    }
  }) : _vm._e()], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Comparação de chamadas",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.getRequestsTotals
    }
  }, [_vm.chartRequestsCompare ? _c("div", {
    staticClass: "chart-area"
  }, [_c("line-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-id": "line-chart-total",
      "chart-data": _vm.chartRequestsCompare,
      "chart-options": _vm.chartOptions
    }
  })], 1) : _vm._e()])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "card"
  }, [_c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Serviços com erros"
    }
  }, [_c("table-services-inactives-calls-list", {
    attrs: {
      list: _vm.details.listNotFetchs || [],
      "status-code": true
    }
  })], 1)], 1)])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;