"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _legacy = require("vue-chartjs/legacy");
var _chart = require("chart.js");
_chart.Chart.register(_chart.Title, _chart.Tooltip, _chart.Legend, _chart.ArcElement, _chart.CategoryScale);
var _default2 = {
  name: 'DoughnutChart',
  components: {
    Doughnut: _legacy.Doughnut
  },
  props: {
    chartData: {
      type: Object,
      default: function _default() {}
    },
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: function _default() {}
    },
    plugins: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: false
        }
      }
    };
  }
};
exports.default = _default2;