"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "section-header"
  }, [_vm._m(0), _vm._v(" "), _c("div", [_c("b-button", {
    staticClass: "btn-new",
    attrs: {
      type: "is-info",
      rounded: ""
    },
    on: {
      click: _vm.addNewClient
    }
  }, [_vm._v("\n        Nova documentação\n      ")])], 1)]), _vm._v(" "), _c("header-filter", {
    attrs: {
      placeholder: "Busque pelo nome do serviço"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "list-items"
  }, [_c("table-list-services-types", {
    attrs: {
      list: _vm.listActives,
      "action-item": _vm.openDetail
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Documentações")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;