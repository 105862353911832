"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'NavLink',
  inject: ['closeNavbar', 'closeDropDown'],
  props: {
    to: {
      type: [String, Object],
      default: undefined
    }
  },
  computed: {
    componentType: function componentType() {
      return this.to ? 'router-link' : 'a';
    }
  },
  methods: {
    closeNav: function closeNav() {
      if (this.closeNavbar) {
        this.closeNavbar();
      }
      if (this.closeDropDown) {
        this.closeDropDown();
      }
    }
  }
};
exports.default = _default;