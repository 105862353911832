"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'Card',
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    noFooterLine: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    raised: {
      type: Boolean,
      default: false
    },
    cardBodyClasses: {
      type: [String, Object, Array],
      default: ''
    },
    headerClasses: {
      type: [String, Object, Array],
      default: ''
    }
  }
};
exports.default = _default;