"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-dashboard"
  }, [_c("div", {
    staticClass: "section-header-title"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Dashboard")]), _vm._v(" "), _c("card-filter-days", {
    staticClass: "mb-6",
    attrs: {
      time: _vm.timeDash,
      times: _vm.times
    },
    on: {
      "update:time": function updateTime($event) {
        _vm.timeDash = $event;
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("card-component", {
    staticClass: "card-services pt-2",
    attrs: {
      title: "Serviços ativos"
    }
  }, [!_vm.isLoading ? _c("h1", [_vm._v(_vm._s(_vm.listConsumption.TotalAPI))]) : _vm._e(), _vm._v(" "), !_vm.isLoading ? _c("b-button", {
    attrs: {
      type: "is-link"
    },
    on: {
      click: _vm.openServicesPage
    }
  }, [_vm._v("\n          Ver mais\n        ")]) : _c("div", [_c("b-skeleton", {
    attrs: {
      circle: "",
      width: "64px",
      height: "64px",
      position: "is-centered"
    }
  }), _vm._v(" "), _c("b-skeleton", {
    attrs: {
      height: "20px",
      position: "is-centered"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-dashboard-monitor", {
    staticClass: "p-2 pl-4 pt-4",
    attrs: {
      total: _vm.listConsumption.TotalAPIRequests,
      "total-success": _vm.listConsumption.TotalAPIRequestsOk,
      "total-error": _vm.listConsumption.TotalAPIRequestsNotOk,
      "last-update": _vm.lastUpdate,
      "no-click": true,
      load: _vm.isLoading
    },
    on: {
      "update:lastUpdate": function updateLastUpdate($event) {
        _vm.lastUpdate = $event;
      },
      "update:last-update": function updateLastUpdate($event) {
        _vm.lastUpdate = $event;
      }
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-api-consult-dead", {
    staticClass: "p-2 pl-4 pt-4",
    attrs: {
      list: _vm.listAPIDead,
      days: _vm.timeDash,
      load: _vm.isLoading
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-api-request", {
    staticClass: "p-2 pl-4 pt-4",
    attrs: {
      "time-dash": _vm.timeDash
    }
  })], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;