"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-users-list"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Consulta Pessoal")]), _vm._v(" "), _c("b-button", {
    staticClass: "btn-new-client",
    attrs: {
      type: "is-info",
      rounded: ""
    },
    on: {
      click: _vm.openModalNew
    }
  }, [_vm._v("\n    Realizar uma nova analise\n  ")]), _vm._v(" "), _c("div", [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("field-search-input", {
    attrs: {
      placeholder: "Pesquise pelo CPF"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)]), _vm._v(" "), _vm.listPersons.length ? _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-table", {
    ref: "multiSortTable",
    attrs: {
      data: _vm.listPersons,
      paginated: "",
      "backend-pagination": "",
      total: _vm.total,
      "per-page": _vm.perPage,
      selected: _vm.selected,
      "sort-icon": _vm.sortIcon,
      "sort-icon-size": _vm.sortIconSize,
      loading: _vm.isLoading,
      "aria-next-label": "Próxima página",
      "aria-previous-label": "Página anterior",
      "aria-page-label": "Página",
      "aria-current-label": "Página atual"
    },
    on: {
      "update:selected": function updateSelected($event) {
        _vm.selected = $event;
      },
      "page-change": _vm.onPageChange
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "name",
      label: "Nome",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n                " + _vm._s(props.row.name) + "\n              ")];
      }
    }], null, false, 3234713498)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "service",
      label: "CPF",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n              " + _vm._s(_vm._f("maskHideCPF")(props.row.document)) + "\n            ")];
      }
    }], null, false, 2695815562)
  })]], 2)], 1)]) : _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 ml-auto col-xl-12 mr-auto"
  }, [_c("card", [_c("div", {
    staticClass: "content-list"
  }, [_c("h4", {
    staticClass: "text-not-found"
  }, [_vm._v("\n              Não temos usuários registrados no o momento.\n            ")])])])], 1)])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;