"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueMaterial = _interopRequireDefault(require("vue-material"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _elementUi = _interopRequireDefault(require("element-ui"));
var _ptBr = _interopRequireDefault(require("element-ui/lib/locale/lang/pt-br"));
require("vue-material/dist/vue-material.min.css");
var _globalDirectives = _interopRequireDefault(require("./globalDirectives"));
var _globalMixins = _interopRequireDefault(require("./globalMixins"));
var _globalComponents = _interopRequireDefault(require("./globalComponents"));
var _vueLazyload = _interopRequireDefault(require("vue-lazyload"));
var _vuelidate = _interopRequireDefault(require("vuelidate"));
var _buefy = _interopRequireDefault(require("buefy"));
require("buefy/dist/buefy.css");
var _vueTheMask = _interopRequireDefault(require("vue-the-mask"));
require("eva-icons/style/scss/eva-icons.scss");
var _vMask = _interopRequireDefault(require("v-mask"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// import 'bootstrap/dist/css/bootstrap.css'
var _default = {
  install: function install(Vue) {
    Vue.use(_vueMaterial.default);
    Vue.use(_elementUi.default, {
      locale: _ptBr.default,
      size: _jsCookie.default.get('size') || 'medium'
    });
    Vue.use(_globalDirectives.default);
    Vue.use(_globalMixins.default);
    Vue.use(_globalComponents.default);
    Vue.use(_buefy.default);
    Vue.use(_vuelidate.default);
    Vue.use(_vueTheMask.default);
    Vue.use(_vMask.default);
    Vue.use(_vueLazyload.default, {
      observer: true,
      // optional
      observerOptions: {
        rootMargin: '0px',
        threshold: 0.1
      }
    });
  }
};
exports.default = _default;