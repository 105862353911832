"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compositionApi = require("@vue/composition-api");
var _CardComponent = _interopRequireDefault(require("@/components/CardComponent.vue"));
var _GrowingNumber = _interopRequireDefault(require("@/components/GrowingNumber.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = (0, _compositionApi.defineComponent)({
  name: 'CardWidget',
  components: {
    GrowingNumber: _GrowingNumber.default,
    CardComponent: _CardComponent.default
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    number: {
      type: [Number, String],
      default: 0
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    isReal: {
      type: Boolean,
      default: false
    }
  }
});
exports.default = _default;