exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"--menuText": "#8F8F8F",
	"--menuBg": "#ffffff",
	"--headerColor": "#2F237D",
	"--headerTextColor": "white",
	"--buttonColor": "#f6b109",
	"--buttonTextColor": "white",
	"--colorAccessorie": "#ffffff80"
};