"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'CheckPassword',
  props: {
    pass: {
      type: String,
      default: ''
    },
    confirmPass: {
      type: String,
      default: ''
    },
    valid: {
      type: Function,
      default: function _default() {}
    }
  },
  computed: {
    passIsMinimun: function passIsMinimun() {
      return this.pass.length >= 8;
    },
    passIsLetterUpper: function passIsLetterUpper() {
      return /[A-Z]/.test(this.pass);
    },
    passIsLetterLower: function passIsLetterLower() {
      return /[a-z]/.test(this.pass);
    },
    passIsNumber: function passIsNumber() {
      return /[0-9]/.test(this.pass);
    },
    passIsEqual: function passIsEqual() {
      return this.pass.length >= 8 && this.pass === this.confirmPass;
    }
  },
  watch: {
    pass: function pass() {
      this.validIsValid();
    },
    confirmPass: function confirmPass() {
      this.validIsValid();
    }
  },
  methods: {
    validIsValid: function validIsValid() {
      var valid = this.passIsMinimun && this.passIsLetterUpper && this.passIsLetterLower && this.passIsNumber && this.passIsEqual;
      if (valid) {
        this.$emit('valid', true);
      } else this.$emit('valid', false);
    }
  }
};
exports.default = _default2;