import { render, staticRenderFns } from "./DetailDashboardItem.vue?vue&type=template&id=89d5fca8&"
import script from "./DetailDashboardItem.vue?vue&type=script&lang=js&"
export * from "./DetailDashboardItem.vue?vue&type=script&lang=js&"
import style0 from "./DetailDashboardItem.vue?vue&type=style&index=0&id=89d5fca8&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('89d5fca8')) {
      api.createRecord('89d5fca8', component.options)
    } else {
      api.reload('89d5fca8', component.options)
    }
    module.hot.accept("./DetailDashboardItem.vue?vue&type=template&id=89d5fca8&", function () {
      api.rerender('89d5fca8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/DetailDashboardItem.vue"
export default component.exports