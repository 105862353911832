"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-client-data-form-businessunit"
  }, [_c("form", {
    attrs: {
      id: "clientDocumentForm"
    },
    on: {
      submit: _vm.saveDocuments
    }
  }, [_c("card", {
    staticClass: "card-customer-finished-form-prospect-component"
  }, [_c("h1", [_vm._v("Unidade de negócio")]), _vm._v(" "), _vm.idClient ? _c("div", {
    staticClass: "list-documents-requests"
  }, [_vm.isAdmin ? _c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewDocument
    }
  }) : _vm._e(), _vm._v(" "), _c("b-table", {
    attrs: {
      data: !_vm.listBusinessUnits.length ? [] : _vm.listBusinessUnits
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c("div", {
          staticClass: "has-text-centered"
        }, [_vm._v("Não há registros")])];
      },
      proxy: true
    }], null, false, 1302487956)
  }, [_c("b-table-column", {
    attrs: {
      field: "BusinessUnitId",
      label: "Código",
      width: "60",
      numeric: "",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.BusinessUnitId) + "\n          ")];
      }
    }], null, false, 699547824)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Name",
      label: "Área"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.Name) + "\n          ")];
      }
    }], null, false, 525453626)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Active",
      label: "Ativo",
      centered: "",
      width: "180"
    }
  }, [_vm._v("\n            Ativo\n          ")]), _vm._v(" "), _vm.isAdmin ? _c("b-table-column", {
    attrs: {
      label: "Excluir",
      centered: "",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          staticClass: "btn-delete",
          attrs: {
            "icon-right": "delete"
          },
          on: {
            click: function click($event) {
              return _vm.confirmDelete(props.row);
            }
          }
        })];
      }
    }], null, false, 1892283845)
  }) : _vm._e()], 1)], 1) : _vm._e()])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;