"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'CardFilterDays',
  props: {
    time: {
      type: [String, Number],
      default: ''
    },
    times: {
      type: Array,
      default: function _default() {
        return [{
          value: 7,
          name: '7 dias'
        }, {
          value: 15,
          name: '15 dias'
        }, {
          value: 30,
          name: '30 dias'
        }];
      }
    }
  },
  data: function data() {
    return {
      newValue: this.time
    };
  },
  computed: {
    timeSelected: {
      get: function get() {
        return this.newValue;
      },
      set: function set(value) {
        this.newValue = value;
        this.$emit('update:time', value);
      }
    }
  }
};
exports.default = _default2;