"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MonitoringReportGeneral = _interopRequireDefault(require("./MonitoringReportGeneral.vue"));
var _MonitoringReportFinancial = _interopRequireDefault(require("./MonitoringReportFinancial"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  name: 'MonitoringReport',
  bodyClass: 'monitoring-reports-page',
  components: {
    MonitoringReportGeneral: _MonitoringReportGeneral.default,
    MonitoringReportFinancial: _MonitoringReportFinancial.default
  },
  data: function data() {
    return {
      tab: 0
    };
  }
};
exports.default = _default;