"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "not-fount-content"
  }, [_c("h3", [_vm._v(_vm._s(_vm.text))]), _vm._v(" "), _c("img", {
    attrs: {
      src: require("@/assets/images/icons/no-results.png")
    }
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;