"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.errorResponseHelper = void 0;
var _router = _interopRequireDefault(require("@/router"));
var _helpers = require("@/helpers");
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
// eslint-disable-next-line no-undef
var display = function display() {
  var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var errorKey = 'default';
  if (typeof error === 'string') {
    errorKey = error;
  } else {
    var response = error.response || undefined;
    if (!response) {
      response = error.data ? error : undefined;
    }
    if (response) {
      if (_typeof(response) === 'object' && response.data) {
        var errorDetail = response.data.details;
        if (Array.isArray(errorDetail) && errorDetail.length > 0) {
          var msg = errorDetail[0];
          response = {
            data: {
              message: msg ? msg.toLowerCase().split(' ').join('_') : ''
            }
          };
        }
      }
      var messageError = _lodash.default.get(response, 'data.message');
      if (messageError) {
        errorKey = messageError.toLowerCase().split(' ').join('_');
      } else if (response.status === 401) {
        errorKey = 'token_expired';
      }
    }
  }
  var message = _router.default.app.$t("errors.".concat(errorKey));
  if (error.message) {
    message = error.message;
  } else {
    if (message.includes('errors.')) {
      message = _router.default.app.$t("errors.default");
    }
  }
  _helpers.messageHelper.error(message);
};
var errorResponseHelper = {
  display: display
};
exports.errorResponseHelper = errorResponseHelper;