"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-service-detail"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-xxl-3"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n              " + _vm._s(_vm.serviceType.Name || "") + "\n            ")])])])])])]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 col-lg-7 col-descriptions"
  }, [_c("div", {
    staticClass: "content-descriptions"
  }, [_c("h3", [_vm._v("Informações")]), _vm._v(" "), _c("p", [_vm._v("\n          " + _vm._s(_vm.serviceType.Description || "") + "\n        ")])]), _vm._v(" "), _vm.detail.List ? _c("div", {
    staticClass: "content-certificates"
  }, [_c("h3", [_vm._v("Serviços vinculados")]), _vm._v(" "), _c("div", {
    staticClass: "list-certs"
  }, [_c("div", {
    staticClass: "columns is-multiline"
  }, _vm._l(_vm.list, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column is-one-quarter"
    }, [_c("card-certificate", {
      attrs: {
        title: item.NameService,
        logo: item.LogoService
      }
    })], 1);
  }), 0)])]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "col-md-12 col-lg-5 col-details"
  }, [_c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component card-sandbox"
  }, [_c("b-button", {
    staticClass: "detail",
    on: {
      click: _vm.openDetail
    }
  }, [_vm._v("\n            Editar\n          ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Nome do tipo de serviço")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.serviceType.Name || ""))])])])]), _vm._v(" "), _c("div", {
    staticClass: "col-12 col-apis-list"
  }, [_c("h3", [_vm._v("\n        Organização fornecedora\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "columns is-multiline"
  }, _vm._l(_vm.list, function (item, idx) {
    return _c("div", {
      key: idx * 4,
      staticClass: "column is-one-third"
    }, [_c("card-monitor", {
      attrs: {
        service: item.NameOrganization,
        logo: item.LogoOrganization
      }
    })], 1);
  }), 0)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;