"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-dash"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Lista de autorizações")]), _vm._v(" "), _c("b-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("b-tab-item", {
    attrs: {
      label: "PENDENTES"
    }
  }, [_c("table-list-consumers-autoried", {
    attrs: {
      list: _vm.listPending,
      "action-item": _vm.openDetail
    }
  })], 1), _vm._v(" "), _c("b-tab-item", {
    attrs: {
      label: "REJEITADOS"
    }
  }, [_c("table-list-consumers-autoried", {
    attrs: {
      list: _vm.listRejects,
      "action-item": _vm.openDetail
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;