"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isArray = isArray;
exports.isExternal = isExternal;
exports.isString = isString;
exports.maxNumber = maxNumber;
exports.replaceAll = replaceAll;
exports.validAlphabets = validAlphabets;
exports.validExistCNPJ = validExistCNPJ;
exports.validLowerCase = validLowerCase;
exports.validURL = validURL;
exports.validUpperCase = validUpperCase;
exports.validUsername = validUsername;
exports.validateCNPJ = validateCNPJ;
exports.validateCPF = validateCPF;
exports.validateNumber = validateNumber;
exports.validateParcels = validateParcels;
var _store = _interopRequireDefault(require("@/store"));
var _buefy = require("buefy");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$', 'i');
  return !!pattern.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ['admin'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}

/**
 * replace all strings
 * @param {string} str
 * @param {string} find
 * @param {string} replace
 * @returns {string}
 */
function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
}

/**
 * validate cpf
 * @param {string} cpf
 * @returns {boolean}
 */
function validateCPF(str) {
  var cpf = str && str.toString();
  if (!cpf || cpf.length < 11) return false;
  var strCPF = replaceAll(cpf, '.', '');
  strCPF = replaceAll(strCPF, '-', '');
  var Soma = 0;
  var Resto;
  if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' || strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' || strCPF === '88888888888' || strCPF === '99999999999') {
    return false;
  }
  for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = Soma * 10 % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
  Soma = 0;
  for (var _i = 1; _i <= 10; _i++) Soma = Soma + parseInt(strCPF.substring(_i - 1, _i)) * (12 - _i);
  Resto = Soma * 10 % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

/**
 * validate cnpj
 * @param {string} s
 * @returns {boolean}
 */
function validateCNPJ(cnpjParam) {
  var cnpj = cnpjParam.replace(/[^\d]+/g, '');
  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) return false;
  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) return false;
  // Cáculo de validação
  var t = cnpj.length - 2;
  var d = cnpj.substring(t);
  var d1 = parseInt(d.charAt(0));
  var d2 = parseInt(d.charAt(1));
  var calc = function calc(x) {
    var n = cnpj.substring(0, x);
    var y = x - 7;
    var s = 0;
    var r = 0;
    for (var i = x; i >= 1; i--) {
      s += n.charAt(x - i) * y--;
      if (y < 2) y = 9;
    }
    r = 11 - s % 11;
    return r > 9 ? 0 : r;
  };
  return (calc(t) === d1 && calc(t + 1)) === d2;
}
function validateNumber(n) {
  if (Math.sign(n) === -1) {
    _buefy.ToastProgrammatic.open({
      duration: 3000,
      message: "Campo n\xE3o pode ser negativo",
      position: 'is-top',
      type: 'is-danger'
    });
    return false;
  } else {
    return true;
  }
}
function maxNumber(n) {
  if (n > 100) {
    return false;
  } else {
    return true;
  }
}
function validExistCNPJ(cnpjParam, action, getter) {
  if (!cnpjParam) return false;
  var cnpj = cnpjParam.replace(/[^\d]+/g, '');
  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) return false;
  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) return false;
  // Verifica se o cnpj já existe
  _store.default.dispatch("".concat(action), cnpjParam);
  if (_store.default.getters["".concat(getter)] && _store.default.getters["".concat(getter)] === true) {
    _buefy.ToastProgrammatic.open({
      duration: 3000,
      message: "<b>CNPJ</b> J\xE1 cadastrado",
      position: 'is-top',
      type: 'is-danger'
    });
    return false;
  }

  // Cáculo de validação
  var t = cnpj.length - 2;
  var d = cnpj.substring(t);
  var d1 = parseInt(d.charAt(0));
  var d2 = parseInt(d.charAt(1));
  var calc = function calc(x) {
    var n = cnpj.substring(0, x);
    var y = x - 7;
    var s = 0;
    var r = 0;
    for (var i = x; i >= 1; i--) {
      s += n.charAt(x - i) * y--;
      if (y < 2) y = 9;
    }
    r = 11 - s % 11;
    return r > 9 ? 0 : r;
  };
  return (calc(t) === d1 && calc(t + 1)) === d2;
}
function validateParcels() {
  var daysToSell = _store.default.getters.getParcels.daysToSell;
  daysToSell = daysToSell.split(',');
  var numberInstallmentsToSell = _store.default.getters.getParcels.numberInstallmentsToSell;

  // eslint-disable-next-line
  if (daysToSell.length == numberInstallmentsToSell) {
    _store.default.commit('errorMut', '');
    return true;
  } else {
    return false;
  }
}