"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "content-service-detail"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-xxl-3"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v(" "), _vm.service.Logo ? _c("img", {
    attrs: {
      src: _vm.service.Logo,
      alt: "logo"
    }
  }) : _vm._e(), _vm._v("\n                " + _vm._s(_vm.service.Name) + "\n                "), _c("span", {
    staticClass: "detail-version"
  }, [_vm._v("v1.0")])])])])])])])]), _vm._v(" "), _c("iframe", {
    staticClass: "template-documentation",
    attrs: {
      src: _vm.urlDocumentation
    }
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;