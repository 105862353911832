"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-monitor-component",
    class: {
      "no-click": _vm.noClick
    },
    on: {
      click: _vm.openDetail
    }
  }, [_c("div", {
    staticClass: "card-header"
  }, [_vm.loading ? _c("b-skeleton", {
    attrs: {
      animated: true
    }
  }) : _vm._e(), _vm._v(" "), _vm.logo ? _c("img", {
    attrs: {
      src: _vm.logo,
      alt: "Imagem serviço"
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "col-detail-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [!_vm.loading ? _c("h3", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._v(" "), _vm.type === "API" && !_vm.loading ? _c("h4", {
    staticClass: "detail"
  }, [_vm._v("Serviço")]) : _vm._e()])])], 1), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_vm.type === "API" ? _c("div", {
    staticClass: "content"
  }, [_c("h1", [_vm._v("1 ms")]), _vm._v(" "), _c("div", {
    staticClass: "detail"
  }, [_vm._v("0 timeout")])]) : _c("div", {
    staticClass: "content"
  }, [_vm.loading ? _c("b-skeleton", {
    attrs: {
      animated: true
    }
  }) : _vm._e(), _vm._v(" "), !_vm.loading ? _c("h1", [_vm._v(_vm._s(_vm.service))]) : _vm._e()], 1)]), _vm._v(" "), _vm.type === "API" ? _c("div", {
    staticClass: "card-footer"
  }, [_c("h6", [_vm._v("Último request")]), _vm._v(" "), _c("div", {
    staticClass: "detail"
  }, [_vm._v("0 horas")]), _vm._v(" "), _c("span", {
    staticClass: "status",
    class: {
      healthy: _vm.active
    }
  }, [_vm._v(_vm._s(_vm.active ? "Ativo" : "Inativo"))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "card-footer"
  }, [_vm.cost ? _c("h6", [_vm._v("Custo")]) : _vm._e(), _vm._v(" "), _vm.cost ? _c("div", {
    staticClass: "detail"
  }, [_vm._v("R$ " + _vm._s(_vm.cost) + " / Requisição")]) : _vm._e(), _vm._v(" "), _vm.showStatus ? _c("span", {
    staticClass: "status",
    class: {
      healthy: _vm.active
    }
  }, [_vm._v(_vm._s(_vm.active ? "Ativo" : "Inativo"))]) : _vm._e(), _vm._v(" "), _vm.loading ? _c("b-skeleton", {
    attrs: {
      width: "20%",
      animated: true
    }
  }) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;