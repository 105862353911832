"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  title: 'Dashboard',
  path: '/dashboard-list',
  icon: 'eva eva-pie-chart-outline',
  roles: [1, 2, 3, 4]
}, {
  title: 'Monitor de Conexões',
  path: '/connection-monitor',
  icon: 'eva eva-activity-outline',
  roles: [1, 2, 3]
}, {
  title: 'Transações Pix',
  path: '/pix-transactions',
  icon: 'eva eva-swap-outline',
  roles: [1, 2, 3]
}, {
  title: 'Relatório OpenFinance',
  path: '/open-finance/dashboard',
  icon: 'eva eva-bar-chart-2-outline',
  roles: [1, 2, 3]
},
// {
//   title: 'Serviços Ativos',
//   path: '/products-services-list',
//   icon: 'eva eva-cube-outline',
//   roles: [1, 3, 4]
// },
{
  title: 'Clientes',
  path: '/clients-list',
  icon: 'eva eva-grid-outline',
  roles: [1]
}, {
  title: 'Usuários',
  path: '/users-list',
  icon: 'eva eva-people-outline',
  roles: [1]
}, {
  title: 'Consulta pessoal',
  path: '/personal-consultation-list',
  icon: 'eva eva-file-text-outline',
  roles: [1, 7]
}, {
  title: 'Open Banking',
  path: '/share-info-detail',
  icon: 'eva eva-bar-chart-2-outline',
  roles: [1, 2, 3]
}, {
  title: 'Gravame',
  path: '/gravame-contract',
  icon: 'eva eva-archive-outline',
  roles: [1],
  children: [{
    title: 'Consultar',
    path: '/gravame-contract/consult',
    icon: 'eva eva-file-text-outline'
  }, {
    title: 'Cadastrar',
    path: '/gravame-contract/register',
    icon: 'eva eva-file-add-outline'
  }, {
    title: 'Alterar',
    path: '/gravame-contract/alter',
    icon: 'eva eva-edit-2-outline'
  }, {
    title: 'Aditivar',
    path: '/gravame-contract/add',
    icon: 'eva eva-activity-outline'
  }, {
    title: 'Redistribuir em Lote',
    path: '/gravame-contract/redistribute',
    icon: 'eva eva-cube-outline'
  }]
},
// {
//   title: 'Autorização de serviços',
//   path: '/autorized-consumers',
//   icon: 'eva eva-lock-outline',
//   roles: [1]
// },
// {
//   title: 'Consumidos',
//   path: '/consumers-list',
//   icon: 'eva eva-cloud-download-outline'
// },
// {
//   title: 'Fornecidos',
//   path: '/providers-list',
//   icon: 'eva eva-cloud-upload-outline'
// },
// {
//   title: 'Beneficiário final',
//   path: '/end-users-list',
//   icon: 'eva eva-people-outline'
// },
// {
//   title: 'Serviços disponíveis',
//   path: '/new-services-list',
//   icon: 'eva eva-shopping-cart-outline',
//   roles: [1, 3]
// },
// {
//   title: 'Tipo de Serviços',
//   path: '/services-types-list',
//   icon: 'eva eva-inbox-outline',
//   roles: [1, 3]
// },
// {
//   title: 'Documentação',
//   path: '/documentation-services',
//   icon: 'eva eva-edit-2-outline',
//   roles: [1]
// },
// {
//   title: 'Financeiro',
//   path: '/monitoring-report',
//   icon: 'eva eva-file-text-outline',
//   roles: [1, 3, 4]
// },
{
  title: 'Acompanhamento',
  path: '/data-tracking',
  icon: 'eva eva-eye-outline',
  roles: [1, 3, 4]
}
// {
//   title: 'Convite Facematch',
//   path: '/invite-facematch-form',
//   icon: 'eva eva-checkmark-circle-outline',
//   roles: [1, 3, 4]
// },
// {
//   title: 'Prova de Vida',
//   path: '/invite-facematch-form-hive',
//   icon: 'eva eva-person-done-outline',
//   roles: [1, 3, 4]
// }
];
exports.default = _default;