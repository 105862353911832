"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'OpenFinanceDashboard',
  bodyClass: 'open-finance-dashboard-page',
  components: {},
  data: function data() {
    return {
      url: 'https://metabase.bbts.com.br/public/dashboard/535f544e-f9f0-44cd-99df-3186ae8564aa',
      idService: null,
      service: {}
    };
  },
  methods: {
    goBack: function goBack() {
      this.$router.back();
    }
  }
};
exports.default = _default;