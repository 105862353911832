"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("nav", {
    staticClass: "navbar",
    class: _vm.classes
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "navbar-translate"
  }, [_vm._t("default", null, null, _vm.slotData), _vm._v(" "), _c("navbar-toggle-button", {
    attrs: {
      toggled: _vm.showMenu
    },
    nativeOn: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.toggle.apply(null, arguments);
      }
    }
  })], 2), _vm._v(" "), _vm.$slots["navbar-menu"] || _vm.$scopedSlots["navbar-menu"] ? _c("div", {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.close,
      expression: "close"
    }],
    staticClass: "navbar-collapse collapse",
    class: [{
      show: _vm.showMenu
    }, {
      "has-image": _vm.menuImage
    }, _vm.navMenuClasses],
    style: _vm.menuImage ? "background: url(".concat(_vm.menuImage, ") 0% 0% / cover;") : "",
    attrs: {
      id: "navigation",
      "data-color": "orange"
    }
  }, [_vm._t("before-menu"), _vm._v(" "), _c("ul", {
    staticClass: "navbar-nav"
  }, [_vm._t("navbar-menu", null, null, _vm.slotData)], 2), _vm._v(" "), _vm._t("after-menu")], 2) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;