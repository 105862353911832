"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compositionApi = require("@vue/composition-api");
var _chunk = _interopRequireDefault(require("lodash/chunk"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = (0, _compositionApi.defineComponent)({
  name: 'Tiles',
  props: {
    maxPerRow: {
      type: Number,
      default: 5
    }
  },
  render: function render(createElement) {
    var renderAncestor = function renderAncestor(elements) {
      return createElement('div', {
        attrs: {
          class: 'tile is-ancestor'
        }
      }, elements.map(function (element) {
        return createElement('div', {
          attrs: {
            class: 'tile is-parent'
          }
        }, [element]);
      }));
    };
    var slots = this.$slots.default.filter(function (item) {
      return item.tag;
    });
    if (slots.length <= this.maxPerRow) {
      return renderAncestor(slots);
    } else {
      return createElement('div', {
        attrs: {
          class: 'is-tiles-wrapper'
        }
      }, (0, _chunk.default)(slots, this.maxPerRow).map(function (group) {
        return renderAncestor(group);
      }));
    }
  }
});
exports.default = _default;