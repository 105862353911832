"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-table", {
    staticClass: "table-list-modern",
    attrs: {
      data: !_vm.list.length ? [] : _vm.list,
      "mobile-cards": true
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c("div", {
          staticClass: "has-text-centered"
        }, [_vm._v("Não há registros")])];
      },
      proxy: true
    }])
  }, [_c("b-table-column", {
    attrs: {
      field: "PersonRole",
      label: "Organização",
      width: "220"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n    " + _vm._s(_vm._f("toUpperCase")(props.row.OrganizationName)) + "\n  ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Name",
      label: "Área"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n    " + _vm._s(_vm._f("toUpperCase")(props.row.BusinessUnitName)) + "\n  ")];
      }
    }])
  }), _vm._v(" "), _vm.showDelete ? _c("b-table-column", {
    attrs: {
      label: "Excluir",
      centered: "",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          staticClass: "btn-delete",
          attrs: {
            "icon-right": "delete"
          },
          on: {
            click: function click($event) {
              return _vm.action(props.row);
            }
          }
        })];
      }
    }], null, false, 3172162896)
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;