"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compositionApi = require("@vue/composition-api");
var _legacy = require("vue-chartjs/legacy");
var _chart = require("chart.js");
_chart.Chart.register(_chart.LineElement, _chart.PointElement, _chart.LineController, _chart.LinearScale, _chart.CategoryScale, _chart.Tooltip);
var _default = (0, _compositionApi.defineComponent)({
  name: 'LineChart',
  components: {
    LineChartElement: _legacy.Line
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    chartData: {
      type: Object,
      default: null
    },
    chartOptions: {
      type: Object,
      default: null
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 150
    }
  }
});
exports.default = _default;