"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-new-services"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Usuários finais")]), _vm._v(" "), _c("div", [_vm.listUsers.length ? _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 ml-auto col-xl-12"
  }, [_c("field-search-input", {
    attrs: {
      placeholder: "Pesquise por CPF, Nome ou E-mail"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.listUsers.length ? _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 ml-auto col-xl-12 mr-auto"
  }, [_c("table-end-user-list", {
    attrs: {
      list: _vm.listUsers,
      "action-open": _vm.actionOpen
    }
  })], 1)]) : _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 ml-auto col-xl-12 mr-auto"
  }, [_c("card", [_c("div", {
    staticClass: "content-list"
  }, [_c("h4", {
    staticClass: "text-not-found"
  }, [_vm._v("Não temos usuários registrados no o momento.")])])])], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;