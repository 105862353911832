"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header-section-components"
  }, [_c("div", {
    staticClass: "col col-title no-padding"
  }, [_c("h3", {
    staticClass: "title-page-destaq"
  }, [_vm._v(_vm._s(_vm.title))])]), _vm._v(" "), _c("div", {
    staticClass: "col col-button"
  }, [_vm.titleButton ? _c("button", {
    staticClass: "btn-action-right",
    on: {
      click: _vm.action
    }
  }, [_vm._v("\n      " + _vm._s(_vm.titleButton) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm.titleButtonTwo ? _c("button", {
    staticClass: "btn-action-right second",
    on: {
      click: _vm.actionTwo
    }
  }, [_vm._v("\n      " + _vm._s(_vm.titleButtonTwo) + "\n    ")]) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;