"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rolesAllowedAdmin = exports.TYPE_USER_USER = exports.TYPE_USER_ADMIN = exports.TYPES_PERSONS = exports.STATUS_CONSUMERS = exports.STATUS_APURATION = exports.OPTIONS_CHART_DEFAULT = exports.MAX_UPLOAD_FILE = exports.MAX_PRODUCT_NAME = exports.LIST_YEARS = exports.LIST_TYPES_USERS = exports.LIST_TYPES_STATUS_SERVICES = exports.LIST_TITLES = exports.LIST_PERSONS_CREDIT = exports.LIST_MONTHS = exports.LIST_GENDERS = exports.LIST_BIOFACE = exports.KEY_USER_LOCAL_STORAGE = void 0;
/**
 * URL
 */
var KEY_USER_LOCAL_STORAGE = 'BACKOFFICE-HIVEPLACE-LCS';

/**
 * ROLES ADMIN
 */
exports.KEY_USER_LOCAL_STORAGE = KEY_USER_LOCAL_STORAGE;
var rolesAllowedAdmin = ['ADMIN_HIVEPLACE'];

/**
 * Typo User
 */
exports.rolesAllowedAdmin = rolesAllowedAdmin;
var TYPE_USER_USER = 'USER';
exports.TYPE_USER_USER = TYPE_USER_USER;
var TYPE_USER_ADMIN = 'ADMIN';
exports.TYPE_USER_ADMIN = TYPE_USER_ADMIN;
var STATUS_APURATION = {
  UNAPPROVED: 'negado',
  PENDING: 'pendente',
  APPROVED: 'aprovado'
};
exports.STATUS_APURATION = STATUS_APURATION;
var MAX_PRODUCT_NAME = 50;

// block files larger than 2 MB
exports.MAX_PRODUCT_NAME = MAX_PRODUCT_NAME;
var MAX_UPLOAD_FILE = 2097152;

// Types persons
exports.MAX_UPLOAD_FILE = MAX_UPLOAD_FILE;
var TYPES_PERSONS = {
  'F': 'Pessoa física',
  'C': 'Pessoa física conjunta',
  'J': 'Pessoa jurídica',
  'E': 'EFPC',
  'U': 'Fundação',
  'G': 'PF Não Residente',
  'K': 'PJ Não Residente',
  'I': 'Fundo de Investimento',
  'D': 'Desconhecido'
};

// CONSUMERS
exports.TYPES_PERSONS = TYPES_PERSONS;
var STATUS_CONSUMERS = {
  'aprove': 1,
  'pending': 2,
  'reject': 3,
  'authorized': 4
};

/**
 * List types users
 */
exports.STATUS_CONSUMERS = STATUS_CONSUMERS;
var LIST_TYPES_USERS = [{
  id: 1,
  idItem: 'TIHive',
  name: 'TI Hive'
}, {
  id: 3,
  idItem: 'AdminBBTS',
  name: 'Usuário Administrativo'
}, {
  id: 2,
  idItem: 'Client',
  name: 'Usuário Cliente'
}, {
  id: 4,
  idItem: 'FinanceiroBackOffice',
  name: 'Usuário Financeiro BackOffice'
}, {
  id: 6,
  idItem: 'FinanceiroClient',
  name: 'Usuário Financeiro Cliente'
}, {
  id: 7,
  idItem: 'PersonAnalise',
  name: 'Consulta Pessoal'
}];

/**
 * List types SERVICES
 */
exports.LIST_TYPES_USERS = LIST_TYPES_USERS;
var LIST_TYPES_STATUS_SERVICES = [{
  id: 1,
  name: 'Disponível'
}, {
  id: 2,
  name: 'Solicitado'
}, {
  id: 3,
  name: 'Autorizado'
}, {
  id: 4,
  name: 'Rejeitado'
}
// { id: 5, name: 'Aprovado' }
];

/**
 * List MONTHS
 */
exports.LIST_TYPES_STATUS_SERVICES = LIST_TYPES_STATUS_SERVICES;
var LIST_MONTHS = [{
  id: '01',
  name: 'Janeiro'
}, {
  id: '02',
  name: 'Fevereiro'
}, {
  id: '03',
  name: 'Março'
}, {
  id: '04',
  name: 'Abril'
}, {
  id: '05',
  name: 'Maio'
}, {
  id: '06',
  name: 'Junho'
}, {
  id: '07',
  name: 'Julho'
}, {
  id: '08',
  name: 'Agosto'
}, {
  id: '09',
  name: 'Setembro'
}, {
  id: '10',
  name: 'Outubro'
}, {
  id: '11',
  name: 'Novembro'
}, {
  id: '12',
  name: 'Dezembro'
}];

/**
 * List Years
 */
exports.LIST_MONTHS = LIST_MONTHS;
var LIST_YEARS = [{
  id: '2021',
  name: '2021'
}, {
  id: '2022',
  name: '2022'
}, {
  id: '2023',
  name: '2023'
}];

/**
 * List Gender
 */
exports.LIST_YEARS = LIST_YEARS;
var LIST_GENDERS = [{
  id: 'F',
  name: 'Feminino'
}, {
  id: 'M',
  name: 'Masculino'
}, {
  id: 'I',
  name: 'Outro'
}];

/**
 * OPTIONS CHART
 */
exports.LIST_GENDERS = LIST_GENDERS;
var OPTIONS_CHART_DEFAULT = {
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false
  },
  scales: {
    y: {
      display: true,
      title: {
        display: true
      }
    },
    x: {
      display: true
    }
  },
  plugins: {
    legend: {
      display: false
    },
    elements: {
      point: {
        radius: 0
      }
    }
  },
  elements: {
    point: {
      radius: 0
    }
  }
};

/**
 * DE/PARA BIOFACE
 */
exports.OPTIONS_CHART_DEFAULT = OPTIONS_CHART_DEFAULT;
var LIST_BIOFACE = [{
  score: '601-1000',
  status: 'SEM RISCO APARENTE'
}, {
  score: '450-600',
  status: 'NEUTRO'
}, {
  score: '200-449',
  status: 'ALERTA DE RISCO'
}, {
  score: '50-199',
  status: 'COM RISCO'
}, {
  score: '0-49',
  status: 'NÃO PASSÍVEL DE ANÁLISE'
}];

/**
 * LIST TITLES
 */
exports.LIST_BIOFACE = LIST_BIOFACE;
var LIST_TITLES = [{
  host: 'localhost',
  title: 'HivePlace - Localhost'
}, {
  host: 'dev.client.hiveplace.com.br',
  title: 'HivePlace - Client'
}, {
  host: 'hom.client.hiveplace.com.br',
  title: 'HivePlace - Client'
}, {
  host: 'preprod.client.hiveplace.com.br',
  title: 'HivePlace - Client'
}, {
  host: 'client.hiveplace.com.br',
  title: 'HivePlace - Client'
}, {
  host: 'dev.backoffice.hiveplace.com.br',
  title: 'HivePlace - BackOffice'
}, {
  host: 'hom.BackOffice.hiveplace.com.br',
  title: 'HivePlace - BackOffice'
}, {
  host: 'preprod.BackOffice.hiveplace.com.br',
  title: 'HivePlace - BackOffice'
}, {
  host: 'BackOffice.hiveplace.com.br',
  title: 'HivePlace - BackOffice'
}];

/**
 * LIST_PERSONS_CREDIT
 */
exports.LIST_TITLES = LIST_TITLES;
var LIST_PERSONS_CREDIT = [{
  type: 'QUEM SOU EU',
  description: 'Pessoas sem histórico de crédito, que possuem somente um histórico demográfico.'
}, {
  type: 'O BEM AMADO',
  description: 'É o público mais assediado pelas empresas, devido seu histórico de crédito impecável.'
}, {
  type: 'FUJAM DE MIM',
  description: 'A sub-classificação mais delicada, que possuem um histórico ruim devido aos inúmeros compromissos sem pagamento.'
}, {
  type: 'PAGO QUANDO PUDER',
  description: 'Pessoas cujo histórico de crédito denunciam uma instabilidade no cumprimento de suas obrigações.'
}, {
  type: 'SEMPRE PRESENTE',
  description: 'Apesar de bom é uma sub-classificação vulnerável às interpretações de crédito do mercado.'
}, {
  type: 'NOVOS ENTRANTES',
  description: 'Agrupa jovens que não possuem histórico de crédito ou adultos que estão tirando cpf recentemente e que por conta disso, não podem ser classificadas.'
}];
exports.LIST_PERSONS_CREDIT = LIST_PERSONS_CREDIT;