"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-list-consumers-autoried-component"
  }, [_vm.list && _vm.list.length ? _c("b-table", {
    attrs: {
      data: _vm.list
    },
    on: {
      click: _vm.actionOpen
    }
  }, [[_c("b-table-column", {
    attrs: {
      label: "Cliente"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.Organization) + "\n      ")];
      }
    }], null, false, 2948043366)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Unidade de negócio"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.BusinessUnit) + "\n      ")];
      }
    }], null, false, 3580406205)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Serviço solicitado"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.DigitalAsset) + "\n      ")];
      }
    }], null, false, 2949123095)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      centered: "",
      width: "270"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticClass: "wrap-buttons-actions"
        }, [_c("b-button", {
          staticClass: "reject",
          on: {
            click: function click($event) {
              return _vm.confirmReject(props.row);
            }
          }
        }, [_vm._v("Rejeitar")]), _vm._v(" "), _c("b-button", {
          staticClass: "accept",
          on: {
            click: function click($event) {
              return _vm.confirmAccept(props.row);
            }
          }
        }, [_vm._v("Ativar")])], 1)];
      }
    }], null, false, 2899382640)
  })]], 2) : _vm._e(), _vm._v(" "), !_vm.list || !_vm.list.length ? _c("div", [_c("h1", {
    staticClass: "empty-title"
  }, [_vm._v("\n      Sem dados no momento\n    ")])]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;