"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_HEADER = exports.TOGGLE_SIDEBAR = exports.TOGGLE_DEVICE = exports.SET_USERS_LIST = exports.SET_TYPES_PERSONS = exports.SET_TYPES_INVESTIDOR_PROFILES = exports.SET_TYPES_APPLICATION_PROFILES = exports.SET_TYPES_ADMINISTRATOR_PROFILES = exports.SET_TOKEN = exports.SET_TEXT_LOADING = exports.SET_SIZE = exports.SET_SERVICE_TYPES_LIST = exports.SET_SERVICE_LIST_CLIENT = exports.SET_SERVICE_LIST_ALL = exports.SET_SERVICE_DETAIL_ITEM = exports.SET_SERVICES_MONITOR_PER_TYPE = exports.SET_ROLES = exports.SET_PERSONAL_ACCOUNTS = exports.SET_PEOPLE_ANALYSIS = exports.SET_OFFICER_LIST = exports.SET_NAME = exports.SET_MUNICIPIO = exports.SET_LOADING = exports.SET_LIST_LAST_RESULTS = exports.SET_LIST_CONSUMPTION = exports.SET_INTRODUCTION = exports.SET_FUNDS_LIST = exports.SET_DOCUMENT_CLIENT_LIST = exports.SET_DOCUMENTS_LIST = exports.SET_DETAIL_OPEN_DASHBOARD = exports.SET_CRITERIO_CONFEDERECAO = exports.SET_CRITERIO_ASSOCIACAO = exports.SET_COUNT_REQUEST_PER_TYPE = exports.SET_COOPERATIVAS = exports.SET_CONNECTIONS_LIST = exports.SET_CLIENT_ITEM = exports.SET_CLIENTS_LIST = exports.SET_CHANNEL_LIST = exports.SET_CENTRAL_CONF = exports.SET_CENTRAL = exports.SET_BUSINES_UNITS_LIST = exports.SET_BILLING_MONTH = exports.SET_AVATAR = exports.SET_ASSETS_TYPES_LIST = exports.SET_ASSETS_LIST = exports.SET_ASSETS_ITEM = exports.SET_ALL_PROVIDERS = exports.SET_ALL_CONSUMERS_NOT_LINK = exports.SET_ALL_CONSUMERS_AUTHORIZATIONS = exports.SET_ALL_CONSUMERS = exports.SET_ACESSOS_LIST = exports.RESET_REGISTRATION_FORM = exports.JOIN_REGISTRATION_FORM = exports.JOIN_FORM_COOPERATIVAS_CREATE = exports.GET_OFFICER_ALL = exports.GET_COMPANY = exports.CLOSE_SIDEBAR = void 0;
// APP
var TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
exports.TOGGLE_SIDEBAR = TOGGLE_SIDEBAR;
var CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
exports.CLOSE_SIDEBAR = CLOSE_SIDEBAR;
var TOGGLE_DEVICE = 'TOGGLE_DEVICE';
exports.TOGGLE_DEVICE = TOGGLE_DEVICE;
var SET_SIZE = 'SET_SIZE';
exports.SET_SIZE = SET_SIZE;
var SET_LOADING = 'SET_LOADING';
exports.SET_LOADING = SET_LOADING;
var SET_TEXT_LOADING = 'SET_TEXT_LOADING';
exports.SET_TEXT_LOADING = SET_TEXT_LOADING;
var UPDATE_HEADER = 'UPDATE_HEADER';
// USER
exports.UPDATE_HEADER = UPDATE_HEADER;
var SET_TOKEN = 'SET_TOKEN';
exports.SET_TOKEN = SET_TOKEN;
var SET_INTRODUCTION = 'SET_INTRODUCTION';
exports.SET_INTRODUCTION = SET_INTRODUCTION;
var SET_NAME = 'SET_NAME';
exports.SET_NAME = SET_NAME;
var SET_AVATAR = 'SET_AVATAR';
exports.SET_AVATAR = SET_AVATAR;
var SET_ROLES = 'SET_ROLES';
exports.SET_ROLES = SET_ROLES;
var SET_TYPES_PERSONS = 'SET_TYPES_PERSONS';
exports.SET_TYPES_PERSONS = SET_TYPES_PERSONS;
var SET_TYPES_APPLICATION_PROFILES = 'SET_TYPES_APPLICATION_PROFILES';
exports.SET_TYPES_APPLICATION_PROFILES = SET_TYPES_APPLICATION_PROFILES;
var SET_TYPES_INVESTIDOR_PROFILES = 'SET_TYPES_INVESTIDOR_PROFILES';
exports.SET_TYPES_INVESTIDOR_PROFILES = SET_TYPES_INVESTIDOR_PROFILES;
var SET_TYPES_ADMINISTRATOR_PROFILES = 'SET_TYPES_ADMINISTRATOR_PROFILES';
exports.SET_TYPES_ADMINISTRATOR_PROFILES = SET_TYPES_ADMINISTRATOR_PROFILES;
var JOIN_REGISTRATION_FORM = 'JOIN_REGISTRATION_FORM';
exports.JOIN_REGISTRATION_FORM = JOIN_REGISTRATION_FORM;
var RESET_REGISTRATION_FORM = 'RESET_REGISTRATION_FORM';
// Files
exports.RESET_REGISTRATION_FORM = RESET_REGISTRATION_FORM;
var SET_CLIENTS_LIST = 'SET_CLIENTS_LIST';
exports.SET_CLIENTS_LIST = SET_CLIENTS_LIST;
var SET_CLIENT_ITEM = 'SET_CLIENT_ITEM';
exports.SET_CLIENT_ITEM = SET_CLIENT_ITEM;
var SET_DOCUMENT_CLIENT_LIST = 'SET_DOCUMENT_CLIENT_LIST';
// Assets
exports.SET_DOCUMENT_CLIENT_LIST = SET_DOCUMENT_CLIENT_LIST;
var SET_ASSETS_LIST = 'SET_ASSETS_LIST';
exports.SET_ASSETS_LIST = SET_ASSETS_LIST;
var SET_ASSETS_ITEM = 'SET_ASSETS_ITEM';
exports.SET_ASSETS_ITEM = SET_ASSETS_ITEM;
var SET_ASSETS_TYPES_LIST = 'SET_ASSETS_TYPES_LIST';
// Documents
exports.SET_ASSETS_TYPES_LIST = SET_ASSETS_TYPES_LIST;
var SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
// Funds
exports.SET_DOCUMENTS_LIST = SET_DOCUMENTS_LIST;
var SET_FUNDS_LIST = 'SET_FUNDS_LIST';
// Acessos
exports.SET_FUNDS_LIST = SET_FUNDS_LIST;
var SET_ACESSOS_LIST = 'SET_ACESSOS_LIST';
// Table
exports.SET_ACESSOS_LIST = SET_ACESSOS_LIST;
var SET_CENTRAL = 'SET_CENTRAL';
exports.SET_CENTRAL = SET_CENTRAL;
var SET_CRITERIO_ASSOCIACAO = 'SET_CRITERIO_ASSOCIACAO';
exports.SET_CRITERIO_ASSOCIACAO = SET_CRITERIO_ASSOCIACAO;
var SET_CRITERIO_CONFEDERECAO = 'SET_CRITERIO_CONFEDERECAO';
exports.SET_CRITERIO_CONFEDERECAO = SET_CRITERIO_CONFEDERECAO;
var SET_CENTRAL_CONF = 'SET_CENTRAL_CONF';
exports.SET_CENTRAL_CONF = SET_CENTRAL_CONF;
var SET_CHANNEL_LIST = 'SET_CHANNEL_LIST';
exports.SET_CHANNEL_LIST = SET_CHANNEL_LIST;
var SET_OFFICER_LIST = 'SET_OFFICER_LIST';
// Cooperativas
exports.SET_OFFICER_LIST = SET_OFFICER_LIST;
var SET_COOPERATIVAS = 'SET_COOPERATIVAS';
exports.SET_COOPERATIVAS = SET_COOPERATIVAS;
var JOIN_FORM_COOPERATIVAS_CREATE = 'JOIN_FORM_COOPERATIVAS_CREATE';
// Municipios
exports.JOIN_FORM_COOPERATIVAS_CREATE = JOIN_FORM_COOPERATIVAS_CREATE;
var SET_MUNICIPIO = 'SET_MUNICIPIO';
// Companhia
exports.SET_MUNICIPIO = SET_MUNICIPIO;
var GET_COMPANY = 'GET_COMPANY';
// officer
exports.GET_COMPANY = GET_COMPANY;
var GET_OFFICER_ALL = 'GET_OFFICER_ALL';
// PRODUCTS
exports.GET_OFFICER_ALL = GET_OFFICER_ALL;
var SET_CONNECTIONS_LIST = 'SET_CONNECTIONS_LIST';
// CONSUMERS
exports.SET_CONNECTIONS_LIST = SET_CONNECTIONS_LIST;
var SET_ALL_CONSUMERS = 'SET_ALL_CONSUMERS';
exports.SET_ALL_CONSUMERS = SET_ALL_CONSUMERS;
var SET_ALL_CONSUMERS_NOT_LINK = 'SET_ALL_CONSUMERS_NOT_LINK';
exports.SET_ALL_CONSUMERS_NOT_LINK = SET_ALL_CONSUMERS_NOT_LINK;
var SET_ALL_CONSUMERS_AUTHORIZATIONS = 'SET_ALL_CONSUMERS_AUTHORIZATIONS';
// PROVIDERS
exports.SET_ALL_CONSUMERS_AUTHORIZATIONS = SET_ALL_CONSUMERS_AUTHORIZATIONS;
var SET_ALL_PROVIDERS = 'SET_ALL_PROVIDERS';
// BUSINES UNITS
exports.SET_ALL_PROVIDERS = SET_ALL_PROVIDERS;
var SET_BUSINES_UNITS_LIST = 'SET_BUSINES_UNITS_LIST';
// Users
exports.SET_BUSINES_UNITS_LIST = SET_BUSINES_UNITS_LIST;
var SET_USERS_LIST = 'SET_USERS_LIST';
// SERVICES
exports.SET_USERS_LIST = SET_USERS_LIST;
var SET_SERVICE_LIST_ALL = 'SET_SERVICE_LIST_ALL';
exports.SET_SERVICE_LIST_ALL = SET_SERVICE_LIST_ALL;
var SET_SERVICE_DETAIL_ITEM = 'SET_SERVICE_DETAIL_ITEM';
exports.SET_SERVICE_DETAIL_ITEM = SET_SERVICE_DETAIL_ITEM;
var SET_SERVICE_LIST_CLIENT = 'SET_SERVICE_LIST_CLIENT';
exports.SET_SERVICE_LIST_CLIENT = SET_SERVICE_LIST_CLIENT;
var SET_SERVICE_TYPES_LIST = 'SET_SERVICE_TYPES_LIST';
//  DASHBOARD
exports.SET_SERVICE_TYPES_LIST = SET_SERVICE_TYPES_LIST;
var SET_LIST_CONSUMPTION = 'SET_LIST_CONSUMPTION';
exports.SET_LIST_CONSUMPTION = SET_LIST_CONSUMPTION;
var SET_LIST_LAST_RESULTS = 'SET_LIST_LAST_RESULTS';
exports.SET_LIST_LAST_RESULTS = SET_LIST_LAST_RESULTS;
var SET_DETAIL_OPEN_DASHBOARD = 'SET_DETAIL_OPEN_DASHBOARD';
// BILLING
exports.SET_DETAIL_OPEN_DASHBOARD = SET_DETAIL_OPEN_DASHBOARD;
var SET_BILLING_MONTH = 'SET_BILLING_MONTH';

// Monitor Type Services
exports.SET_BILLING_MONTH = SET_BILLING_MONTH;
var SET_SERVICES_MONITOR_PER_TYPE = 'SET_SERVICES_MONITOR_PER_TYPE';
exports.SET_SERVICES_MONITOR_PER_TYPE = SET_SERVICES_MONITOR_PER_TYPE;
var SET_COUNT_REQUEST_PER_TYPE = 'SET_COUNT_REQUEST_PER_TYPE';

// People Analysis
exports.SET_COUNT_REQUEST_PER_TYPE = SET_COUNT_REQUEST_PER_TYPE;
var SET_PEOPLE_ANALYSIS = 'SET_PEOPLE_ANALYSIS';

// Open Bankin - BBTS
exports.SET_PEOPLE_ANALYSIS = SET_PEOPLE_ANALYSIS;
var SET_PERSONAL_ACCOUNTS = 'SET_PERSONAL_ACCOUNTS';
exports.SET_PERSONAL_ACCOUNTS = SET_PERSONAL_ACCOUNTS;