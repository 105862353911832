"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _export = _interopRequireDefault(require("@/assets/styles/_export.scss"));
var _mockMenu = _interopRequireDefault(require("@/store/mocks/mock-menu"));
var _auth = require("@/utils/auth");
var _constants = require("@/config/constants");
var _validate = require("@/utils/validate");
var _version2 = _interopRequireDefault(require("@/version"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _default = {
  components: {
    SidebarItem: _SidebarItem.default
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['sidebar', 'device'])), {}, {
    variables: function variables() {
      return _export.default;
    },
    permissionRoutes: function permissionRoutes() {
      var _this = this;
      var menu = _mockMenu.default.filter(function (i) {
        return i.roles.includes(_this.user.roleId);
      });
      return menu;
    },
    activeMenu: function activeMenu() {
      var _this$$route = this.$route,
        meta = _this$$route.meta,
        path = _this$$route.path;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    isCollapse: function isCollapse() {
      return this.device === 'mobile' ? false : !this.sidebar.opened;
    },
    buttonColor: function buttonColor() {
      return '#000';
    },
    photoValid: function photoValid() {
      if ((0, _validate.validURL)(this.user.imageUrl)) return true;
      return false;
    },
    user: function user() {
      return (0, _auth.getUser)().user;
    },
    descriptionType: function descriptionType() {
      var _this2 = this;
      if (!this.user.roleId) return '';
      var type = _constants.LIST_TYPES_USERS.find(function (i) {
        return i.id === _this2.user.roleId;
      });
      return type.name;
    },
    version: function version() {
      return _version2.default;
    },
    isMobile: function isMobile() {
      return this.device === 'mobile';
    }
  }),
  methods: {
    openDetailProfile: function openDetailProfile() {
      this.$router.push('/profile/user');
    }
  }
};
exports.default = _default;