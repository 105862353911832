"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
_vue.default.use(_vueRouter.default);
var _default = new _vueRouter.default({
  linkExactActiveClass: 'active',
  routes: [{
    path: '/full-page',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/FullPage.vue'));
      });
    },
    children: [{
      meta: {
        title: 'Login'
      },
      path: '/login',
      name: 'login',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Login.vue'));
        });
      }
    }, {
      meta: {
        title: 'Recuperação de Senha'
      },
      path: '/recovery-pass',
      name: 'RecoveryPass',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/RecoveryPass.vue'));
        });
      }
    }, {
      meta: {
        title: 'Resetar a Senha'
      },
      path: '/reset-pass',
      name: 'ResetPass',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ResetPass.vue'));
        });
      }
    }]
  }, {
    path: '/',
    redirect: '/login'
  }, {
    path: '/dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    meta: {
      title: 'ÍNDICES'
    },
    children: [{
      path: '/dashboard-list',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Dashboard.vue'));
        });
      },
      name: 'dashboard',
      meta: {
        title: 'Dashboard',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/detail-dashboard-item-list/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DetailDashboardItem.vue'));
        });
      },
      name: 'DetailDashboardItem',
      meta: {
        title: 'Dashboard',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/dashboard-list-errors/:bu/:asset/:status/:days',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DashboardListErrors.vue'));
        });
      },
      name: 'DashboardListErrors',
      meta: {
        title: 'Dashboard',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/dashboard-details-service',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DashboardDetails/DashboardDetailsService.vue'));
        });
      },
      name: 'DashboardDetailsService',
      meta: {
        title: 'Dashboard Details',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/clients',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/clients-list',
      name: 'ClientsList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ClientList.vue'));
        });
      },
      meta: {
        title: 'Clientes',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/clients-form',
      name: 'ClientForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ClientForm/index.vue'));
        });
      },
      meta: {
        title: 'Clientes',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/clients-form/:id',
      name: 'ClientFormEdit',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ClientForm/index.vue'));
        });
      },
      meta: {
        title: 'Clientes',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/clients-detail/:id',
      name: 'ClientDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ClientDetail.vue'));
        });
      },
      meta: {
        title: 'Cliente',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/business-unit-form/:id',
      name: 'BusinessUnitForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/BusinessUnitForm/index.vue'));
        });
      },
      meta: {
        title: 'Area de negocio',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/users',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/users-list',
      name: 'UsersList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/UsersList.vue'));
        });
      },
      meta: {
        title: 'Usuário',
        roles: [1]
      }
    }, {
      path: '/users-form',
      name: 'UsersForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/UsersForm.vue'));
        });
      },
      meta: {
        title: 'Usuário',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/users-form/:id',
      name: 'UsersFormEdit',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/UsersForm.vue'));
        });
      },
      meta: {
        title: 'Usuário',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/users-detail/:id',
      name: 'UserDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/UserDetail/index.vue'));
        });
      },
      meta: {
        title: 'Usuário',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/connection-monitor-pages',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/connection-monitor',
      name: 'ConnectionMonitor',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConnectionMonitor.vue'));
        });
      },
      meta: {
        title: 'Monitor de Conexões',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/connection-service-detail/:id',
      name: 'ConnectionServiceDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConnectionServiceDetail.vue'));
        });
      },
      meta: {
        title: 'Detalhes do serviço',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/products-services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/products-services-list',
      name: 'ProductsServicesList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ProductsServices.vue'));
        });
      },
      meta: {
        title: 'Produtos / Serviços',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/service-detail-extract/:id',
      name: 'ServiceDetailExtract',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceDetailExtract.vue'));
        });
      },
      meta: {
        title: 'Detalhes do Serviço',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/pix-transactions',
      name: 'PixTransactions',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/PixTransactions.vue'));
        });
      },
      meta: {
        title: 'Transações Pix',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/consumers',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/consumers-list',
      name: 'ConsumersList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConsumersList.vue'));
        });
      },
      meta: {
        title: 'Consumidores',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/consumers-detail/:id',
      name: 'ConsumersDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConsumersProvidersDetail.vue'));
        });
      },
      meta: {
        title: 'Consumidor',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/providers',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/providers-list',
      name: 'ProvidersList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ProvidersList.vue'));
        });
      },
      meta: {
        title: 'Fornecedores',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/providers-detail/:id',
      name: 'ProvidersDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConsumersProvidersDetail.vue'));
        });
      },
      meta: {
        title: 'Fornecedor',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/end-users',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/end-users-list',
      name: 'EndUsersList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/EndUsersList.vue'));
        });
      },
      meta: {
        title: 'Usuários Finais',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/new-services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/new-services-list',
      name: 'NewServicesList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/NewServicesList.vue'));
        });
      },
      meta: {
        title: 'Novos serviços',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/service-detail/:id',
      name: 'ServiceDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceDetail.vue'));
        });
      },
      meta: {
        title: 'Detalhes do serviço',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/new-apis',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/new-service',
      name: 'NewServiceForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/NewServiceForm/index.vue'));
        });
      },
      meta: {
        title: 'Novos API',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/update-service/:id',
      name: 'UpdateServiceForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/NewServiceForm/index.vue'));
        });
      },
      meta: {
        title: 'Novos API',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/autorizeds',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/autorized-consumers',
      name: 'AutorizedConsumers',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/AutorizedConsumers.vue'));
        });
      },
      meta: {
        title: 'Autorização de serviços',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/profiles',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/profile/user',
      name: 'ProfileUser',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ProfileUser.vue'));
        });
      },
      meta: {
        title: 'Perfil de usuário',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/services/test/sandbox/:id',
      name: 'ServiceTextSandbox',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceTextSandbox.vue'));
        });
      },
      meta: {
        title: 'Teste em Sandbox',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/services/documentation/:id',
      name: 'ServicePortalDocumentation',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServicePortalDocumentation.vue'));
        });
      },
      meta: {
        title: 'Teste em Sandbox',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/services/batch-processing/:id',
      name: 'BatchProcessingList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/BatchProcessingList.vue'));
        });
      },
      meta: {
        title: 'Processamento em lote',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/detail-service/:id',
      name: 'DetailService',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DetailService.vue'));
        });
      },
      meta: {
        title: 'Detalhe do Serviço',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/detail-service/:service/:typeRequest',
      name: 'DetailServiceReception',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DetailServiceReception.vue'));
        });
      },
      meta: {
        title: 'Detalhe do Serviço de Recepção',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/extract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/extract/finances/:id',
      name: 'ExtractFinances',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractFinances.vue'));
        });
      },
      meta: {
        title: 'Extrato de fornecedor',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/extract/consumer/:id',
      name: 'ExtractFinancesConsumer',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractFinancesConsumer.vue'));
        });
      },
      meta: {
        title: 'Extrato de consumidor',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/extract/requests/:id',
      name: 'ExtractRequests',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractRequests.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/extract/requests-error/:id',
      name: 'ExtractRequestsError',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractRequests.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições de erro',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/extract/requests/consumer/:id',
      name: 'ExtractRequestsByConsumer',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractRequestsbyConsumer.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/extract/requests-error/consumer/:id',
      name: 'ExtractRequestsErrorByConsumer',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractRequestsbyConsumer.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/extract/results/:id',
      name: 'ExtractResults',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractResults.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições de erro',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/extract/documents/financial/:id',
      name: 'ExtractFinancialDocuments',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractFinancialDocuments.vue'));
        });
      },
      meta: {
        title: 'Extrato Financeiro',
        roles: [1, 2, 4]
      }
    }]
  }, {
    path: '/services-types',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/services-types-list',
      name: 'ServiceTypesList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceTypes.vue'));
        });
      },
      meta: {
        title: 'Tipos de serviço',
        roles: [1, 2, 3]
      }
    }, {
      path: '/service-type/:id',
      name: 'ServiceTypesDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceTypesDetail.vue'));
        });
      },
      meta: {
        title: 'Detalhe do Tipo de serviço',
        roles: [1, 2, 3]
      }
    }]
  }, {
    path: '/documentation',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/documentation-services',
      name: 'DocumentationServices',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DocumentationServices.vue'));
        });
      },
      meta: {
        title: 'Documentação de serviços',
        roles: [1]
      }
    }, {
      path: '/documentation/edit/:id',
      name: 'DocumentationServiceEdit',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DocumentationServiceEdit.vue'));
        });
      },
      meta: {
        title: 'Editar documentação',
        roles: [1]
      }
    }]
  }, {
    path: '/people-analysis',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/personal-consultation-list',
      name: 'PersonalConsultation',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/PersonalConsultation.vue'));
        });
      },
      meta: {
        title: 'Consulta Pessoal',
        roles: [1, 3, 4, 7]
      }
    }, {
      path: '/personal-consultation/:id',
      name: 'PersonalConsultationDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/PersonalConsultationDetail/index.vue'));
        });
      },
      meta: {
        title: 'Consulta Pessoal',
        roles: [1, 3, 4, 7]
      }
    }]
  }, {
    path: '/share-info',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/share-info-detail',
      name: 'ShareInfo',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ShareInfo.vue'));
        });
      },
      meta: {
        title: 'Compartilhamento',
        roles: [1, 3, 4]
      }
    }]
  }, {
    path: '/gravame-contract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/gravame-contract/consult',
      name: 'Consult',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame.vue'));
        });
      },
      meta: {
        title: 'Gravame',
        roles: [1, 3, 4]
      }
    }, {
      path: '/gravame-contract/register',
      name: 'Register',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame/Register.vue'));
        });
      },
      meta: {
        title: 'Cadastrar',
        roles: [1, 3, 4]
      }
    }, {
      path: '/gravame-contract/alter',
      name: 'Alter',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame/Alter.vue'));
        });
      },
      meta: {
        title: 'Alterar',
        roles: [1, 3, 4]
      }
    }, {
      path: '/gravame-contract/add',
      name: 'Add',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame/Add.vue'));
        });
      },
      meta: {
        title: 'Aditivar',
        roles: [1, 3, 4]
      }
    }, {
      path: '/gravame-contract/redistribute',
      name: 'Redistribute',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame/Redistribute.vue'));
        });
      },
      meta: {
        title: 'Redistribuir em Lote',
        roles: [1, 3, 4]
      }
    }]
  }, {
    path: '/monitoring-report-dash',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/monitoring-report',
      name: 'MonitoringReport',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/MonitoringReport/index.vue'));
        });
      },
      meta: {
        title: 'Monitoramento de seriços',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/data-tracking',
      name: 'DataTracking',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DataTracking/index.vue'));
        });
      },
      meta: {
        title: 'Acompanhamento',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/invite-facematch',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/invite-facematch-form',
      name: 'InviteFacematchForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/InviteFacematch/index.vue'));
        });
      },
      meta: {
        title: 'Convite Facematch',
        roles: [1, 2, 3, 4]
      }
    }, {
      path: '/invite-facematch-form-hive',
      name: 'InviteFacematchFormHive',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/InviteFacematchHive/index.vue'));
        });
      },
      meta: {
        title: 'Convite Facematch',
        roles: [1, 2, 3, 4]
      }
    }]
  }, {
    path: '/open-finance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/open-finance/dashboard',
      name: 'OpenFinanceDashboard',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/OpenFinanceDashboard.vue'));
        });
      },
      meta: {
        title: 'Dashboard OpenFinance',
        roles: [1, 2, 3]
      }
    }]
  }],
  scrollBehavior: function scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});
exports.default = _default;