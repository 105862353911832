"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-dashboard-details-service"
  }, [_vm._m(0), _vm._v(" "), _c("tiles", {
    attrs: {
      "max-per-row": 3
    }
  }, [_c("div", {
    staticClass: "card-filters"
  }, [_c("h3", [_vm._v("Período:")]), _vm._v(" "), _c("div", {
    staticClass: "content-filters"
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("b-datepicker", {
    attrs: {
      locale: "pt-BR",
      placeholder: "Selecione o périodo",
      "mobile-native": false,
      range: ""
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1), _vm._v(" "), _c("b-button", {
    attrs: {
      "icon-right": "magnify"
    },
    on: {
      click: _vm.getDetails
    }
  }, [_vm._v("\n          Filtrar\n        ")])], 1)])]), _vm._v(" "), _c("tiles", {
    attrs: {
      "max-per-row": 3
    }
  }, [_c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-primary",
      number: _vm.requests.totaRequests || 0,
      label: "TOTAL REQUISIÇÕES"
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.requests.totaRequestsSuccess || 0,
      label: "TOTAL 200"
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.requests.totaRequestsErrors || 0,
      label: "TOTAL 400"
    }
  })], 1), _vm._v(" "), _c("card-component", {
    attrs: {
      title: "Resultado Analise Erros 400",
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.getDetails
    }
  }, [_c("table-list-requests", {
    attrs: {
      list: _vm.requests.verifyErrorsFaceMatch || []
    }
  })], 1), _vm._v(" "), _c("card-component", {
    attrs: {
      title: "Resultado Analise 200",
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.getDetails
    }
  }, [_c("table-list-requests", {
    attrs: {
      list: _vm.requests.verifySuccessFaceMatch || []
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_vm._v("\n          Acompanhamento de dados\n        ")])])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;