"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'NRadio',
  props: {
    label: {
      type: [String, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Boolean],
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      cbId: ''
    };
  },
  computed: {
    model: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    inlineClass: function inlineClass() {
      if (this.inline) {
        return "form-check-inline";
      }
      return '';
    }
  },
  created: function created() {
    this.cbId = Math.random().toString(16).slice(2);
  }
};
exports.default = _default;