"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-api-request-component"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [!_vm.data.length && !_vm.isLoading ? _c("h1", [_c("img", {
    attrs: {
      src: require("@/assets/images/icon-checked-grenn.png")
    }
  }), _vm._v(" Sem requisições até o momento\n    ")]) : _c("b-table", {
    ref: "multiSortTable",
    attrs: {
      data: _vm.data,
      paginated: "",
      "backend-pagination": "",
      total: _vm.total,
      "per-page": _vm.perPage,
      selected: _vm.selected,
      "sort-icon": _vm.sortIcon,
      "sort-icon-size": _vm.sortIconSize,
      loading: _vm.isLoading,
      "aria-next-label": "Next page",
      "aria-previous-label": "Previous page",
      "aria-page-label": "Page",
      "aria-current-label": "Current page"
    },
    on: {
      "update:selected": function updateSelected($event) {
        _vm.selected = $event;
      },
      "page-change": _vm.onPageChange
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "Supplier",
      label: "Fornecedor",
      sortable: "",
      searchable: _vm.dateSearchable
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(_vm._f("toUpperCase")(props.row.provider)) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "service",
      label: "Serviço",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(_vm._f("toUpperCase")(props.row.service)) + "\n        ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "dateRequest",
      label: "Horário",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(_vm._f("dateComplete")(props.row.dateRequest)) + "\n        ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "consumer",
      label: "Consumidor",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(_vm._f("toUpperCase")(props.row.consumer)) + "\n        ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "transactionID",
      label: "Transaction ID",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(_vm.minusTransactionId(props.row.transactionID) || "--") + "\n        ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "httpStatus",
      label: "Status",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("small", {
          staticClass: "type-call",
          class: {
            error: props.row.httpStatus >= 400,
            success: props.row.httpStatus < 300
          },
          attrs: {
            title: props.row.httpStatus
          }
        }, [_vm._v(_vm._s(props.row.httpStatus))])];
      }
    }])
  })]], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("\n        Últimas Requisições\n      ")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;