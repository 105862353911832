"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'NPagination',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: function validator(value) {
        return ['default', 'primary', 'danger', 'success', 'warning', 'info'].includes(value);
      }
    },
    pageCount: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 1
    },
    prevItemClasses: {
      type: [String, Object],
      default: null
    },
    itemClasses: {
      type: [String, Object],
      default: null
    },
    nextItemClasses: {
      type: [String, Object],
      default: null
    }
  },
  data: function data() {
    return {
      defaultPagesToDisplay: 5
    };
  },
  computed: {
    paginationClass: function paginationClass() {
      return "pagination-".concat(this.type);
    },
    totalPages: function totalPages() {
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay: function pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage: function minPage() {
      if (this.value >= this.pagesToDisplay) {
        var pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        var newMaxPage = pagesToAdd + this.value;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.value - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage: function maxPage() {
      if (this.value >= this.pagesToDisplay) {
        var pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        var newMaxPage = pagesToAdd + this.value;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    }
  },
  watch: {
    perPage: function perPage() {
      this.$emit('input', 1);
    },
    total: function total() {
      this.$emit('input', 1);
    }
  },
  methods: {
    range: function range(min, max) {
      var arr = [];
      for (var i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePage: function changePage(item) {
      this.$emit('input', item);
    },
    nextPage: function nextPage() {
      if (this.value < this.totalPages) {
        this.$emit('input', this.value + 1);
      }
    },
    prevPage: function prevPage() {
      if (this.value > 1) {
        this.$emit('input', this.value - 1);
      }
    }
  }
};
exports.default = _default;