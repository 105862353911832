"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CardApiMonitor',
  props: {
    title: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  methods: {
    onDownload: function onDownload() {
      if (this.link) window.open(this.link);
    }
  }
};
exports.default = _default;