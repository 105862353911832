"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-monitoring-report-financial"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-half"
  }, [_c("b-field", {
    staticClass: "field-search",
    attrs: {
      label: "Selecione o serviço desejado"
    }
  }, [_c("b-taginput", {
    attrs: {
      data: _vm.filteredListServices,
      autocomplete: "",
      "allow-new": true,
      "open-on-focus": false,
      field: "Name",
      icon: "label",
      placeholder: "Busque o serviço desejado",
      maxtags: 1
    },
    on: {
      typing: _vm.getFilteredTags
    },
    model: {
      value: _vm.servicesSelecteds,
      callback: function callback($$v) {
        _vm.servicesSelecteds = $$v;
      },
      expression: "servicesSelecteds"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      label: "Selecione o mês de referência",
      placeholder: "Selecione o mês",
      items: _vm.listMonths,
      option: "id",
      "name-item": "name"
    },
    model: {
      value: _vm.monthSelected,
      callback: function callback($$v) {
        _vm.monthSelected = $$v;
      },
      expression: "monthSelected"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column column-filter"
  }, [_c("b-button", {
    staticClass: "btn-filter is-info",
    on: {
      click: _vm.updateDataItems
    }
  }, [_vm._v("Filtrar")])], 1)]), _vm._v(" "), _c("tiles", {
    attrs: {
      "max-per-row": 3
    }
  }, [_c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-primary",
      number: _vm.totalFaturamento || 0,
      "is-real": true,
      label: "TOTAL FATURAMENTO"
    }
  })], 1), _vm._v(" "), _c("tiles", {
    attrs: {
      "max-per-row": 3
    }
  }, [_c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.totalProvider || 0,
      "is-real": true,
      label: "TOTAL PROVEDOR"
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.totalHive || 0,
      "is-real": true,
      label: "TOTAL MARGEM HIVE"
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-success",
      number: _vm.totalRequests,
      label: "QUANTIDADE DE REQUISIÇÕES"
    }
  })], 1), _vm._v(" "), _c("b-progress", {
    attrs: {
      type: "is-success",
      max: 450000,
      value: _vm.totalFaturamento,
      "show-value": ""
    }
  }, [_vm._v("\n    " + _vm._s(_vm._f("maskMoney")(_vm.totalFaturamento)) + " / R$ 450.000,00\n  ")]), _vm._v(" "), _c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Demonstrativo de consultas diárias"
    }
  }, [_c("table-demonstrative", {
    attrs: {
      list: _vm.listDemonstrative
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_vm._v("\n          Monitoramento de serviços\n        ")])])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;