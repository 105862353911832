"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("b-tabs", {
    staticClass: "block",
    attrs: {
      position: "is-centered"
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c("b-tab-item", {
    attrs: {
      label: "Todos os serviços"
    }
  }, [_c("monitoring-report-general")], 1), _vm._v(" "), _c("b-tab-item", {
    attrs: {
      label: "Serviço específicos"
    }
  }, [_vm.tab === 1 ? _c("monitoring-report-financial") : _vm._e()], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;