"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-client-data-form-businessunit"
  }, [_c("form", {
    attrs: {
      id: "clientDocumentForm"
    },
    on: {
      submit: _vm.saveDocuments
    }
  }, [_c("card", {
    staticClass: "card-customer-finished-form-prospect-component"
  }, [_c("h1", [_vm._v("Serviços autorizados")]), _vm._v(" "), _vm.BusinessUnit ? _c("div", {
    staticClass: "list-services-authorizeds-requests"
  }, [_vm.isAdmin ? _c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewDocument
    }
  }) : _vm._e(), _vm._v(" "), _c("b-table", {
    attrs: {
      data: !_vm.listServices.length ? [] : _vm.listServices,
      hoverable: true,
      focusable: true,
      "mobile-cards": true
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c("div", {
          staticClass: "has-text-centered"
        }, [_vm._v("Não há registros")])];
      },
      proxy: true
    }], null, false, 1302487956)
  }, [_c("b-table-column", {
    attrs: {
      field: "DigitalAssetId",
      label: "Código",
      width: "40",
      numeric: "",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.DigitalAssetId) + "\n          ")];
      }
    }], null, false, 4130056474)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Name",
      label: "Serviço"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.Name) + "\n          ")];
      }
    }], null, false, 525453626)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Email",
      label: "E-mail"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.Email) + "\n          ")];
      }
    }], null, false, 2475469969)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Organization",
      label: "Proprietário"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(_vm.getNameOrganization(props.row.Organization)) + "\n          ")];
      }
    }], null, false, 1743639533)
  }), _vm._v(" "), _vm.isAdmin ? _c("b-table-column", {
    attrs: {
      label: "Excluir",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          staticClass: "btn-delete",
          attrs: {
            "icon-right": "delete"
          },
          on: {
            click: function click($event) {
              return _vm.confirmDelete(props.row);
            }
          }
        })];
      }
    }], null, false, 1892283845)
  }) : _vm._e()], 1)], 1) : _vm._e()])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;