"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("card", {
    staticClass: "form-new-service-pages"
  }, [_c("form", {
    attrs: {
      id: "customerDataForm"
    },
    on: {
      submit: _vm.checkForm
    }
  }, [_c("app-input", {
    attrs: {
      label: "Nome do serviço",
      placeholder: "Digite o nome do serviço",
      error: _vm.$v.form.Name.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Name, "$model", $$v);
      },
      expression: "$v.form.Name.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "field-type-service"
  }, [_c("app-select", {
    attrs: {
      label: "Tipo de serviço",
      placeholder: "Selecione o tipo de serviço",
      error: _vm.$v.form.DigitalAssetType.$error,
      items: _vm.listServiceTypes,
      option: "DigitalAssetTypeId",
      "name-item": "Name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.DigitalAssetType, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.DigitalAssetType.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.DigitalAssetType, "$model", $$v);
      },
      expression: "$v.form.DigitalAssetType.$model"
    }
  }), _vm._v(" "), _c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewTypeService
    }
  })], 1), _vm._v(" "), _c("app-select", {
    attrs: {
      error: _vm.$v.form.Organization.$error,
      label: "Cliente",
      placeholder: "Selecione o provedor do serviço",
      items: _vm.listAllClients,
      option: "OrganizationId",
      "name-item": "Name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Organization, "$error", $event);
      }
    },
    model: {
      value: _vm.clientUser,
      callback: function callback($$v) {
        _vm.clientUser = $$v;
      },
      expression: "clientUser"
    }
  }), _vm._v(" "), _vm.listBusinessUnits.length ? _c("app-select", {
    attrs: {
      error: _vm.$v.form.BusinessUnitId.$error,
      label: "Área de negócio",
      placeholder: "Área de negócio",
      items: _vm.listBusinessUnits,
      option: "BusinessUnitId",
      "name-item": "Name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.BusinessUnitId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.BusinessUnitId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.BusinessUnitId, "$model", $$v);
      },
      expression: "$v.form.BusinessUnitId.$model"
    }
  }) : _vm._e(), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Descrição do serviço",
      placeholder: "Descreva brevemente",
      error: _vm.$v.form.Description.$error,
      type: "textarea",
      max: "1500"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Description, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Description.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Description, "$model", $$v);
      },
      expression: "$v.form.Description.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "SLA",
      placeholder: "15 ms",
      mask: ["###### ms"],
      error: _vm.$v.form.Sla.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Sla, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Sla.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Sla, "$model", $$v);
      },
      expression: "$v.form.Sla.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Telefone do suporte",
      placeholder: "+55 (00) 0 0000-0000",
      mask: ["+## (##) # ####-####", "+## (##) ####-####"],
      error: _vm.$v.form.Phone.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Phone, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Phone.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Phone, "$model", $$v);
      },
      expression: "$v.form.Phone.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "E-mail do suporte",
      placeholder: "suporte@provedor.com",
      error: _vm.$v.form.Email.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Email, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Email.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Email, "$model", $$v);
      },
      expression: "$v.form.Email.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Webhook",
      placeholder: "Webhook do serviço",
      error: _vm.$v.form.Webhook.$error,
      type: "password"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Webhook, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Webhook.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Webhook, "$model", $$v);
      },
      expression: "$v.form.Webhook.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "URI de documentação",
      placeholder: "URI de documentação",
      error: _vm.$v.form.UrlDocumentation.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.UrlDocumentation, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.UrlDocumentation.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.UrlDocumentation, "$model", $$v);
      },
      expression: "$v.form.UrlDocumentation.$model"
    }
  }), _vm._v(" "), _c("b-field", {
    class: _vm.$v.form.Logo.$error ? "is-danger" : "",
    attrs: {
      label: "Enviar logomarca"
    }
  }, [_c("b-upload", {
    attrs: {
      accept: "image/*",
      "drag-drop": ""
    },
    model: {
      value: _vm.fileLogo,
      callback: function callback($$v) {
        _vm.fileLogo = $$v;
      },
      expression: "fileLogo"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.fileLogo ? _c("p", [_vm._v("Busque ou arraste o arquivo de imagem aqui")]) : _c("p", [_vm._v(_vm._s(_vm.fileLogo.name || "Arquivo selecionado"))])], 1)]), _vm._v(" "), _vm.logoValid && !_vm.fileLogo ? _c("img", {
    attrs: {
      src: _vm.form.Logo,
      alt: "Logotipo"
    }
  }) : _vm._e()])], 1), _vm._v(" "), _c("b-field", {
    class: _vm.$v.form.Swagger.$error ? "is-danger" : "",
    attrs: {
      label: "Enviar Swagger"
    }
  }, [_c("b-upload", {
    attrs: {
      "drag-drop": "",
      accept: ".json"
    },
    model: {
      value: _vm.fileSwagger,
      callback: function callback($$v) {
        _vm.fileSwagger = $$v;
      },
      expression: "fileSwagger"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.fileSwagger ? _c("p", [_vm._v("Busque ou arraste o arquivo .json aqui")]) : _c("p", [_vm._v(_vm._s(_vm.fileSwagger.name || "Arquivo selecionado"))])], 1)]), _vm._v(" "), _vm.swaggerValid && !_vm.fileSwagger ? _c("pre", [_vm._v("          " + _vm._s(_vm.serviceForm.Swagger) + "\n        ")]) : _vm._e()])], 1), _vm._v(" "), _vm.idService ? _c("b-field", {
    attrs: {
      label: "Range de custo"
    }
  }) : _vm._e(), _vm._v(" "), _vm.idService ? _c("div", {
    staticClass: "list-rates-requests"
  }, [_c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewLineCost
    }
  }), _vm._v(" "), _vm._l(_vm.listRangesCost, function (item, idx) {
    return _c("b-field", {
      key: idx,
      attrs: {
        grouped: ""
      }
    }, [_c("app-input", {
      attrs: {
        expanded: "",
        label: "De",
        mask: ["#", "##", "###", "####", "#####", "######", "#######", "########", "#########", "##########", "###########", "############"],
        placeholder: "0",
        disabled: ""
      },
      model: {
        value: item.InitialConsumption,
        callback: function callback($$v) {
          _vm.$set(item, "InitialConsumption", $$v);
        },
        expression: "item.InitialConsumption"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        expanded: "",
        label: "Até",
        mask: ["#", "##", "###", "####", "#####", "######", "#######", "########", "#########", "##########", "###########", "############"],
        placeholder: "0"
      },
      model: {
        value: item.FinalConsumption,
        callback: function callback($$v) {
          _vm.$set(item, "FinalConsumption", $$v);
        },
        expression: "item.FinalConsumption"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        expanded: "",
        label: "Custo",
        placeholder: "R$",
        "type-input": "money"
      },
      model: {
        value: item.Price,
        callback: function callback($$v) {
          _vm.$set(item, "Price", $$v);
        },
        expression: "item.Price"
      }
    }), _vm._v(" "), _c("b-button", {
      staticClass: "remove-item",
      attrs: {
        "icon-right": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.confirmDelete("cost", item, idx);
        }
      }
    })], 1);
  })], 2) : _vm._e(), _vm._v(" "), _vm.idService ? _c("b-field", {
    attrs: {
      label: "Range de venda"
    }
  }) : _vm._e(), _vm._v(" "), _vm.idService ? _c("div", {
    staticClass: "list-rates-requests"
  }, [_c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewLineSale
    }
  }), _vm._v(" "), _vm._l(_vm.listRangesSales, function (item, idx) {
    return _c("b-field", {
      key: idx,
      attrs: {
        grouped: ""
      }
    }, [_c("app-input", {
      attrs: {
        expanded: "",
        label: "De",
        mask: ["#", "##", "###", "####", "#####", "######", "#######", "########", "#########", "##########", "###########", "############"],
        placeholder: "0",
        disabled: ""
      },
      model: {
        value: item.InitialConsumption,
        callback: function callback($$v) {
          _vm.$set(item, "InitialConsumption", $$v);
        },
        expression: "item.InitialConsumption"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        expanded: "",
        label: "Até",
        mask: ["#", "##", "###", "####", "#####", "######", "#######", "########", "#########", "##########", "###########", "############"],
        placeholder: "0"
      },
      model: {
        value: item.FinalConsumption,
        callback: function callback($$v) {
          _vm.$set(item, "FinalConsumption", $$v);
        },
        expression: "item.FinalConsumption"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        expanded: "",
        label: "Custo",
        placeholder: "R$",
        "type-input": "money"
      },
      model: {
        value: item.Price,
        callback: function callback($$v) {
          _vm.$set(item, "Price", $$v);
        },
        expression: "item.Price"
      }
    }), _vm._v(" "), _c("b-button", {
      staticClass: "remove-item",
      attrs: {
        "icon-right": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.confirmDelete("sales", item, idx);
        }
      }
    })], 1);
  })], 2) : _vm._e(), _vm._v(" "), _c("b-field", {
    staticClass: "field-buttons"
  }, [_c("b-button", {
    staticClass: "btn-save-button",
    attrs: {
      "native-type": "submit"
    }
  }, [_vm._v("\n        Salvar serviço\n      ")])], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;