"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("modal", {
    attrs: {
      show: true,
      "show-close": true
    },
    on: {
      close: _vm.close
    }
  }, [_c("h1", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("Adicionar cliente e area de négocio")]), _vm._v(" "), _c("form", {
    attrs: {
      id: "formNewClientPerson"
    }
  }, [_c("app-select", {
    attrs: {
      label: "Cliente",
      placeholder: "Selecione o cliente",
      items: _vm.listClients,
      option: "OrganizationId",
      "name-item": "Name"
    },
    model: {
      value: _vm.clientUser,
      callback: function callback($$v) {
        _vm.clientUser = $$v;
      },
      expression: "clientUser"
    }
  }), _vm._v(" "), _vm.listBusinessUnits.length ? _c("app-select", {
    attrs: {
      label: "Área de negócio",
      placeholder: "Área de negócio",
      items: _vm.listBusinessUnits,
      option: "BusinessUnitId",
      "name-item": "Name"
    },
    model: {
      value: _vm.clientBusinessUnit,
      callback: function callback($$v) {
        _vm.clientBusinessUnit = $$v;
      },
      expression: "clientBusinessUnit"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("b-button", {
    attrs: {
      slot: "footer",
      type: "is-info"
    },
    on: {
      click: _vm.checkForm
    },
    slot: "footer"
  }, [_vm._v("\n    Salvar\n  ")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;