"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-extract-finances-detail"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-xxl-3"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n              Extrato de Resultados - " + _vm._s(_vm.service.Name) + "\n            ")])])])])])]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 col-lg-3 col-filter-date"
  }, [_c("div", {
    staticClass: "card-filters"
  }, [_c("h3", [_vm._v("Período:")]), _vm._v(" "), _c("div", {
    staticClass: "content-filters"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Selecione o mês",
      items: _vm.listMonths,
      option: "id",
      "name-item": "name"
    },
    model: {
      value: _vm.selectMonth,
      callback: function callback($$v) {
        _vm.selectMonth = $$v;
      },
      expression: "selectMonth"
    }
  }), _vm._v(" "), _c("app-select", {
    attrs: {
      placeholder: "Selecione o ano",
      items: _vm.listYears,
      option: "id",
      "name-item": "name"
    },
    model: {
      value: _vm.selectYear,
      callback: function callback($$v) {
        _vm.selectYear = $$v;
      },
      expression: "selectYear"
    }
  })], 1), _vm._v(" "), _c("b-button", {
    staticClass: "btn-filter",
    on: {
      click: _vm.getDetailsBilling
    }
  }, [_vm._v("Filtrar")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "col-md-12 col-lg-9 col-details"
  }, [_c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "header-title"
  }, [_c("h2", [_vm._v("Extrato")]), _vm._v(" "), _c("h4", [_vm._v("Data de consulta: " + _vm._s(_vm._f("dateDetail")(_vm.now)))])]), _vm._v(" "), _vm.listBillings.length ? _c("div", _vm._l(_vm.listBillings, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "card-detail-call"
    }, [_c("span", {
      staticClass: "hour"
    }, [_vm._v(_vm._s(item.BillingMonth) + "/" + _vm._s(item.BillingYear))]), _vm._v(" "), _c("span", {
      staticClass: "type"
    }, [_vm._v("BU " + _vm._s(item.BusinessUnit))]), _vm._v(" "), _c("h2", {
      staticClass: "detail"
    }, [_vm._v("\n              " + _vm._s(_vm._f("maskMoney")(item.Profit)) + "\n            ")]), _vm._v(" "), _c("div", {
      staticClass: "details-itens"
    }, [_c("span", [_vm._v("\n                Custo: " + _vm._s(_vm._f("maskMoney")(item.CostValue / item.OperationsTotal)) + "\n              ")]), _vm._v(" "), _c("span", [_vm._v("\n                Venda: " + _vm._s(_vm._f("maskMoney")(item.SalesValue / item.OperationsTotal)) + "\n              ")]), _vm._v(" "), _c("span", [_vm._v("\n                Requisições: " + _vm._s(_vm._f("maskNumber")(item.OperationsTotal)) + "\n              ")]), _vm._v(" "), _c("span", [_vm._v("\n                Consumer: " + _vm._s(_vm._f("toUpperCase")(item.BusinessUnitName)) + "\n              ")])]), _vm._v(" "), item.ErrorRate ? _c("span", {
      staticClass: "type-call",
      class: {
        success: item.success
      }
    }, [_vm._v("\n              Taxa de erro " + _vm._s(_vm._f("percent")(item.ErrorRate)) + "\n            ")]) : _vm._e()]);
  }), 0) : _c("div", [_c("h4", {
    staticClass: "text-not-found"
  }, [_vm._v("Sem dados suficientes para o período selecionado.")])])])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;