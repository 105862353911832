"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "has-logo",
    attrs: {
      id: "div-sidebar-component"
    }
  }, [!_vm.isMobile ? _c("div", {
    staticClass: "card-client-details"
  }, [_c("h3", [_vm._v("HivePlace")])]) : _vm._e(), _vm._v(" "), !_vm.isMobile ? _c("div", {
    staticClass: "card-client-user-details",
    on: {
      click: _vm.openDetailProfile
    }
  }, [!_vm.photoValid ? _c("img", {
    attrs: {
      src: require("@/assets/images/avatar-default.png")
    }
  }) : _c("img", {
    attrs: {
      src: _vm.user.imageUrl
    }
  }), _vm._v(" "), !_vm.isCollapse ? _c("div", {
    staticClass: "data-user"
  }, [_c("h3", [_vm._v(_vm._s(_vm.user.name))]), _vm._v(" "), _c("h4", [_vm._v(_vm._s(_vm.descriptionType))])]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c("el-menu", {
    attrs: {
      id: "div-sidebar-menu",
      "default-active": _vm.activeMenu,
      "background-color": _vm.variables["--menuBg"],
      "text-color": _vm.variables["--menuText"],
      "unique-opened": false,
      "active-text-color": _vm.buttonColor,
      "collapse-transition": false,
      "default-openeds": [1],
      mode: "vertical"
    }
  }, _vm._l(_vm.permissionRoutes, function (route) {
    return _c("sidebar-item", {
      key: route.path,
      attrs: {
        id: "div-sidebar-menuItem",
        item: route,
        "base-path": route.path,
        collapse: _vm.isCollapse
      }
    });
  }), 1), _vm._v(" "), _c("div", {
    staticClass: "tag-version-content"
  }, [_c("span", [_vm._v("V. " + _vm._s(_vm.version))])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;