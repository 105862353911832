"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _default = {
  name: 'FgInput',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    labelClasses: {
      type: String,
      default: ''
    },
    inputClasses: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    addonRightIcon: {
      type: String,
      default: ''
    },
    addonLeftIcon: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      focused: false
    };
  },
  computed: {
    listeners: function listeners() {
      return _objectSpread(_objectSpread({}, this.$listeners), {}, {
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      });
    },
    hasIcon: function hasIcon() {
      var _this$$slots = this.$slots,
        addonRight = _this$$slots.addonRight,
        addonLeft = _this$$slots.addonLeft;
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
    }
  },
  methods: {
    updateValue: function updateValue(evt) {
      var value = evt.target.value;
      this.$emit('input', value);
    },
    onFocus: function onFocus(value) {
      this.focused = true;
      this.$emit('focus', value);
    },
    onBlur: function onBlur(value) {
      this.focused = false;
      this.$emit('blur', value);
    }
  }
};
exports.default = _default;