"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compositionApi = require("@vue/composition-api");
var _default = (0, _compositionApi.defineComponent)({
  name: 'FullPage',
  created: function created() {
    this.$store.dispatch('toggleFullPage', true);
  },
  beforeDestroy: function beforeDestroy() {
    this.$store.dispatch('toggleFullPage', false);
  }
});
exports.default = _default;