"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-times-documentoscopia-component"
  }, [_c("b-table", {
    attrs: {
      data: _vm.list
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "totalRequests",
      label: "Total de Requisições"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.totalRequests) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "scoreOld",
      label: "Score Anterior",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.scoreOld || "--") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "resultadoBioface",
      label: "Resultado BIOFACE"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("small", {
          staticClass: "type-call",
          class: {
            error: props.row.resultadoBioface === "COM RISCO" || props.row.resultadoBioface === "ALERTA DE RISCO",
            success: props.row.resultadoBioface === "SEM RISCO APARENTE",
            warning: props.row.resultadoBioface === "NEUTRO"
          },
          attrs: {
            title: props.row.resultadoBioface
          }
        }, [_vm._v(_vm._s(_vm._f("translateBioface")(props.row.resultadoBioface)))])];
      }
    }])
  })]], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;