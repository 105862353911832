"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "help checkpass-component"
  }, [_c("h3", [_vm._v("A senha deve conter o seguinte:")]), _vm._v(" "), _c("p", {
    class: _vm.passIsMinimun ? "success" : "danger"
  }, [_c("b-icon", {
    attrs: {
      icon: _vm.passIsMinimun ? "check" : "close"
    }
  }), _vm._v(" Mínimo de senha de 8 caracteres")], 1), _vm._v(" "), _c("p", {
    class: _vm.passIsLetterUpper ? "success" : "danger"
  }, [_c("b-icon", {
    attrs: {
      icon: _vm.passIsLetterUpper ? "check" : "close"
    }
  }), _vm._v(" Ao menos uma letra maiúscula")], 1), _vm._v(" "), _c("p", {
    class: _vm.passIsLetterLower ? "success" : "danger"
  }, [_c("b-icon", {
    attrs: {
      icon: _vm.passIsLetterLower ? "check" : "close"
    }
  }), _vm._v(" Ao menos uma letra minúscula")], 1), _vm._v(" "), _c("p", {
    class: _vm.passIsNumber ? "success" : "danger"
  }, [_c("b-icon", {
    attrs: {
      icon: _vm.passIsNumber ? "check" : "close"
    }
  }), _vm._v(" Ao menos um número")], 1), _vm._v(" "), _c("p", {
    class: _vm.passIsEqual ? "success" : "danger"
  }, [_c("b-icon", {
    attrs: {
      icon: _vm.passIsEqual ? "check" : "close"
    }
  }), _vm._v(" As senhas devem ser idênticas")], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;