"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-pro-det"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-xxl-3"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v(" "), _vm.client.logo ? _c("img", {
    attrs: {
      src: _vm.client.logo,
      alt: "logo"
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/images/logos/logo-default.png"),
      alt: "logo"
    }
  }), _vm._v("\n              " + _vm._s(_vm.client.name) + "\n            ")])])])])])]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 col-lg-7 col-descriptions"
  }, [_c("div", {
    staticClass: "content-descriptions"
  }, [_c("h3", [_vm._v("Informações")]), _vm._v(" "), _c("p", [_vm._v("\n          " + _vm._s(_vm.client.description) + "\n        ")])]), _vm._v(" "), _vm.documents ? _c("div", {
    staticClass: "content-certificates"
  }, [_c("h3", [_vm._v("Documentos")]), _vm._v(" "), _c("div", {
    staticClass: "list-certs"
  }, _vm._l(_vm.documents, function (item, idx) {
    return _c("card-certificate", {
      key: idx,
      attrs: {
        title: item.Name,
        logo: require("@/assets/images/icons/document.png"),
        link: item.Link
      }
    });
  }), 1)]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "col-md-12 col-lg-5 col-details"
  }, [_c("h3", [_vm._v("OVerview")]), _vm._v(" "), _c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component card-sandbox"
  }, [_c("a", {
    staticClass: "detail",
    on: {
      click: _vm.openForm
    }
  }, [_vm._v("\n            Editar\n          ")])]), _vm._v(" "), _vm.isAdmin ? _c("div", {
    staticClass: "card-detail-component card-sandbox"
  }, [_c("a", {
    staticClass: "detail",
    on: {
      click: _vm.addNewBusinessUnit
    }
  }, [_vm._v("\n            Adicionar unidades de negócio\n          ")])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Nome")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.client.businessName || "--"))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Site")]), _vm._v(" "), _c("a", {
    staticClass: "detail",
    attrs: {
      href: _vm.client.site,
      target: "_blank",
      rel: "noopener noreferrer"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.client.site || "--") + "\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Telefone")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("maskPhoneComplete")(_vm.client.phone)))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "service"
    }
  }, [_vm._v("Setor")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.client.setor || "--"))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "phone"
    }
  }, [_vm._v("Sede")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.client.address || "--"))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "mail"
    }
  }, [_vm._v("CNPJ")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("maskCNPJ")(_vm.client.document)))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "mail"
    }
  }, [_vm._v("Código do Cliente")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v("\n            " + _vm._s(_vm.client.OrganizationId) + "\n          ")])])])]), _vm._v(" "), _c("div", {
    staticClass: "col-12 col-support"
  }, [_c("h3", [_vm._v("\n        Unidades de negócio\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "list-support"
  }, [_c("b-table", {
    staticClass: "table-list-modern",
    attrs: {
      data: !_vm.listBusinessUnits.length ? [] : _vm.listBusinessUnits,
      narrowed: true,
      hoverable: true,
      focusable: true,
      "mobile-cards": true
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c("div", {
          staticClass: "has-text-centered"
        }, [_vm._v("Não há registros")])];
      },
      proxy: true
    }])
  }, [_c("b-table-column", {
    attrs: {
      field: "BusinessUnitId",
      label: "Código",
      width: "60",
      numeric: "",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "click",
          on: {
            click: function click($event) {
              return _vm.editBusinessUnits(props.row);
            }
          }
        }, [_vm._v(_vm._s(props.row.BusinessUnitId))])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Name",
      label: "Área"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "click",
          on: {
            click: function click($event) {
              return _vm.editBusinessUnits(props.row);
            }
          }
        }, [_vm._v(_vm._s(props.row.Name))])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "IsProvider",
      label: "Tipo",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "click",
          on: {
            click: function click($event) {
              return _vm.editBusinessUnits(props.row);
            }
          }
        }, [_vm._v("\n              " + _vm._s(props.row.IsProvider ? "Fornecedor" : "Consumidor") + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Active",
      label: "Ativo",
      width: "110",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "click",
          on: {
            click: function click($event) {
              return _vm.editBusinessUnits(props.row);
            }
          }
        }, [_vm._v("Ativo")])];
      }
    }])
  }), _vm._v(" "), _vm.isAdmin ? _c("b-table-column", {
    attrs: {
      label: "Excluir",
      centered: "",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          staticClass: "btn-delete",
          attrs: {
            "icon-right": "delete"
          },
          on: {
            click: function click($event) {
              return _vm.confirmDelete(props.row);
            }
          }
        })];
      }
    }], null, false, 1892283845)
  }) : _vm._e()], 1)], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;