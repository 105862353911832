"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * You can register global mixins here
 */

var GlobalMixins = {
  install: function install(Vue) {
    Vue.mixin({
      mounted: function mounted() {
        var bodyClass = this.$options.bodyClass;
        if (bodyClass) {
          document.body.classList.add(bodyClass);
        }
      },
      beforeDestroy: function beforeDestroy() {
        var bodyClass = this.$options.bodyClass;
        if (bodyClass) {
          document.body.classList.remove(bodyClass);
        }
      }
    });
  }
};
var _default = GlobalMixins;
exports.default = _default;