"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'HeaderPage',
  props: {
    title: {
      type: String,
      default: ''
    },
    titleButton: {
      type: String,
      default: ''
    },
    action: {
      type: Function,
      default: function _default() {}
    }
  }
};
exports.default = _default2;