"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "field-search-input-component"
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.placeholder
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  })])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;