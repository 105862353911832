"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("card-component", [_c("div", {
    staticClass: "level is-mobile"
  }, [_c("div", {
    staticClass: "level-item is-centered"
  }, [_c("div", {
    staticClass: "is-widget-label"
  }, [_c("h3", {
    staticClass: "subtitle is-spaced"
  }, [_vm._v("\n          " + _vm._s(_vm.label) + "\n        ")]), _vm._v(" "), _c("h1", {
    staticClass: "title"
  }, [_c("growing-number", {
    attrs: {
      value: _vm.number,
      prefix: _vm.prefix,
      suffix: _vm.suffix,
      "is-real": _vm.isReal
    }
  })], 1)])]), _vm._v(" "), _vm.icon ? _c("div", {
    staticClass: "level-item has-widget-icon is-centered"
  }, [_c("div", {
    staticClass: "is-widget-icon"
  }, [_c("b-icon", {
    attrs: {
      icon: _vm.icon,
      size: "is-large",
      type: _vm.type
    }
  })], 1)]) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;