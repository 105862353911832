"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("modal", {
    staticClass: "modal-warning-component",
    attrs: {
      show: true
    }
  }, [_c("h4", {
    staticClass: "title title-up",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("Deseja executar está ação?")]), _vm._v(" "), _c("div", [_vm._v("\n    " + _vm._s(_vm.text) + " "), _vm.name ? _c("b", [_vm._v(" " + _vm._s(_vm.name || "") + " ")]) : _vm._e(), _vm._v("?\n  ")]), _vm._v(" "), _c("template", {
    slot: "footer"
  }, [_c("b-button", {
    attrs: {
      type: "is-danger",
      rounded: ""
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v("\n      Excluir\n    ")]), _vm._v(" "), _c("b-button", {
    attrs: {
      type: "is-info",
      rounded: ""
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("\n      Cancelar\n    ")])], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;