"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("./components");
var _vuex = require("vuex");
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _document = document,
  body = _document.body;
var WIDTH = 992; // refer to Bootstrap's responsive design
var _default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Sidebar: _components.Sidebar,
    Headerbar: _components.Headerbar,
    Footerbar: _components.Footerbar
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['sidebar', 'device', 'isLoading'])), {}, {
    isNoShowNavMenu: function isNoShowNavMenu() {
      return this.$route.meta.back && this.device === 'mobile';
    },
    isHiddenNavs: function isHiddenNavs() {
      return this.$route.meta.fullPage || this.device === 'mobile' && this.$route.meta.mobileFullPage;
    },
    isMobile: function isMobile() {
      return this.device === 'mobile';
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
        noSidebar: this.isNoShowNavMenu
      };
    }
  }),
  watch: {
    $route: function $route(route) {
      if (this.device === 'mobile' && this.sidebar.opened) {
        this.closeSideBar({
          withoutAnimation: false
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler);
  },
  mounted: function mounted() {
    var isMobile = this.$_isMobile();
    if (isMobile) {
      this.toggleDevice('mobile');
      this.closeSideBar({
        withoutAnimation: true
      });
    }
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)(['toggleDevice', 'closeSideBar', 'toggleSideBar'])), {}, {
    $_isMobile: function $_isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler: function $_resizeHandler() {
      if (!document.hidden) {
        var isMobile = this.$_isMobile();
        this.toggleDevice(isMobile ? 'mobile' : 'desktop');
        if (isMobile) {
          this.closeSideBar({
            withoutAnimation: true
          });
        }
      }
    },
    toggle: function toggle() {
      this.toggleSideBar();
    }
  })
};
exports.default = _default;