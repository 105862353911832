"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("@/components");
var _default2 = {
  name: 'TableClientsList',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    actionOpen: {
      type: Function,
      default: function _default() {}
    },
    delCoop: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      description: ''
    };
  },
  methods: {
    confirmReject: function confirmReject(item) {
      // this.$buefy.dialog.prompt({
      //   message: `Por favor, descreva os motivos da rejeição do consumidor.`,
      //   inputAttrs: {
      //     placeholder: 'Descreva o motivo',
      //     maxlength: 250,
      //     type: 'text'
      //   },
      //   trapFocus: true,
      //   onConfirm: (value) => this.reject(value, item)
      // })
      this.$buefy.modal.open({
        parent: this,
        component: _components.ModalConfirmReject,
        hasModalCard: true,
        customClass: 'modal-confirm-reject-component',
        trapFocus: true,
        canCancel: ['escape', 'outside'],
        props: {
          item: item
        }
      });
    },
    confirmAccept: function confirmAccept(item) {
      this.$buefy.modal.open({
        parent: this,
        component: _components.ModalWarning,
        hasModalCard: true,
        trapFocus: true,
        props: {
          text: 'Deseja realmente aceitar essa solicitação',
          action: this.accept,
          name: item.Name,
          idItem: item.BusinessUnitId
        }
      });
    },
    reject: function reject(desc, item) {
      // this.$buefy.toast.open(`Descricao: ${desc}`)
    },
    accept: function accept(item) {}
  }
};
exports.default = _default2;