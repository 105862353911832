"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("card-component", {
    staticClass: "card-login"
  }, [_c("div", {
    staticClass: "logo-container"
  }, [_c("h1", {
    staticClass: "title"
  }, [_vm._v("HIVEPlace")]), _vm._v(" "), _c("p", {
    staticClass: "sub-title"
  }, [_vm._v("Para recuperar sua senha, informe seu endereço de e-mail para receber as informações:")])]), _vm._v(" "), _c("form", {
    attrs: {
      id: "app-login"
    },
    on: {
      submit: _vm.checkForm
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.Mail.$error,
      label: "Endereço de e-mail",
      placeholder: "Informe seu E-mail",
      type: "email"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Mail, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Mail.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Mail, "$model", $$v);
      },
      expression: "$v.form.Mail.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card-footer"
  }, [!_vm.isMobile ? _c("div", {
    staticClass: "column"
  }, [_c("router-link", {
    staticClass: "link footer-link",
    attrs: {
      tag: "a",
      to: "/login",
      exact: ""
    }
  }, [_vm._v("Retornar para o login")])], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-button", {
    attrs: {
      rounded: "",
      disabled: _vm.$v.form.$invalid,
      "native-type": "submit",
      type: "is-primary"
    }
  }, [_vm._v("Enviar")])], 1)])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;