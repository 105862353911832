"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate",
    class: _vm.switchClass
  }, [_c("div", {
    staticClass: "bootstrap-switch-container",
    on: {
      click: function click($event) {
        return _vm.triggerToggle();
      }
    }
  }, [_c("span", {
    staticClass: "bootstrap-switch-handle-on"
  }, [_vm._t("on", function () {
    return [_vm._v("\n        " + _vm._s(_vm.onText) + "\n      ")];
  })], 2), _vm._v(" "), _c("span", {
    staticClass: "bootstrap-switch-label"
  }), _vm._v(" "), _c("span", {
    staticClass: "bootstrap-switch-handle-off bootstrap-switch-default"
  }, [_vm._t("off", function () {
    return [_vm._v("\n        " + _vm._s(_vm.offText) + "\n      ")];
  })], 2)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;