"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compositionApi = require("@vue/composition-api");
var _default = (0, _compositionApi.defineComponent)({
  name: 'CardComponent',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    headerIcon: {
      type: String,
      default: null
    }
  },
  emits: ['header-icon-click'],
  methods: {
    headerIconClick: function headerIconClick() {
      this.$emit('header-icon-click');
    }
  }
});
exports.default = _default;