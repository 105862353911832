"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.purchasePlanService = void 0;
var _http = require("@/config/http");
var createPurchasePlan = function createPurchasePlan(payload) {
  return (0, _http.http)({
    url: "/backoffice/purchasePlan/",
    method: 'POST',
    data: payload
  });
};
var updatePurchasePlan = function updatePurchasePlan(payload, assetPlanId) {
  return (0, _http.http)({
    url: "/backoffice/purchasePlan/".concat(assetPlanId),
    method: 'PUT',
    data: payload
  });
};
var deletePurchasePlan = function deletePurchasePlan(assetPlanId) {
  return (0, _http.http)({
    url: "/backoffice/purchasePlan/".concat(assetPlanId),
    method: 'DELETE'
  });
};
var fetchPurchasePlan = function fetchPurchasePlan(idService) {
  return (0, _http.http)({
    url: "/backoffice/purchasePlan/asset/".concat(idService),
    method: 'GET'
  });
};
var createSalesPlan = function createSalesPlan(payload) {
  return (0, _http.http)({
    url: "/backoffice/salesPlan/",
    method: 'POST',
    data: payload
  });
};
var updateSalesPlan = function updateSalesPlan(payload, assetPlanId) {
  return (0, _http.http)({
    url: "/backoffice/salesPlan/".concat(assetPlanId),
    method: 'PUT',
    data: payload
  });
};
var fetchSalesPlan = function fetchSalesPlan(idService) {
  return (0, _http.http)({
    url: "/backoffice/salesPlan/asset/".concat(idService),
    method: 'GET'
  });
};
var deleteSalesPlan = function deleteSalesPlan(assetPlanId) {
  return (0, _http.http)({
    url: "/backoffice/salesPlan/".concat(assetPlanId),
    method: 'DELETE'
  });
};
var purchasePlanService = {
  createPurchasePlan: createPurchasePlan,
  updatePurchasePlan: updatePurchasePlan,
  fetchPurchasePlan: fetchPurchasePlan,
  deletePurchasePlan: deletePurchasePlan,
  createSalesPlan: createSalesPlan,
  updateSalesPlan: updateSalesPlan,
  fetchSalesPlan: fetchSalesPlan,
  deleteSalesPlan: deleteSalesPlan
};
exports.purchasePlanService = purchasePlanService;