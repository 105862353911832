"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-group",
    class: [{
      "input-group": _vm.hasIcon
    }, {
      "has-danger": _vm.error
    }, {
      "input-group-focus": _vm.focused
    }, {
      "has-label": _vm.label || _vm.$slots.label
    }]
  }, [_vm._t("label", function () {
    return [_vm.label ? _c("label", {
      staticClass: "control-label",
      class: _vm.labelClasses
    }, [_vm._v("\n      " + _vm._s(_vm.label) + "\n      "), _vm.required ? _c("span", [_vm._v("*")]) : _vm._e()]) : _vm._e()];
  }), _vm._v(" "), _c("div", {
    class: [{
      "input-group": _vm.hasIcon
    }]
  }, [_vm._t("addonLeft", function () {
    return [_vm.addonLeftIcon ? _c("div", {
      staticClass: "input-group-prepend"
    }, [_c("i", {
      staticClass: "input-group-text",
      class: _vm.addonLeftIcon
    })]) : _vm._e()];
  }), _vm._v(" "), _vm._t("default", function () {
    return [_c("input", _vm._g(_vm._b({
      staticClass: "form-control",
      class: [{
        valid: _vm.value && !_vm.error
      }, _vm.inputClasses],
      attrs: {
        "aria-describedby": "addon-right addon-left"
      },
      domProps: {
        value: _vm.value
      }
    }, "input", _vm.$attrs, false), _vm.listeners))];
  }), _vm._v(" "), _vm._t("addonRight", function () {
    return [_vm.addonRightIcon ? _c("span", {
      staticClass: "input-group-addon input-group-append"
    }, [_c("i", {
      staticClass: "input-group-text",
      class: _vm.addonRightIcon
    })]) : _vm._e()];
  }), _vm._v(" "), _vm._t("infoBlock"), _vm._v(" "), _vm._t("helpBlock", function () {
    return [_vm.error ? _c("div", {
      staticClass: "text-danger invalid-feedback",
      class: {
        "mt-2": _vm.hasIcon
      },
      staticStyle: {
        display: "block"
      }
    }, [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]) : _vm._e()];
  })], 2)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;