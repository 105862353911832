"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'NavbarToggleButton',
  props: {
    toggled: Boolean
  }
};
exports.default = _default;