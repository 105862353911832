"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-ranges-escalonamento-components"
  }, [_c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "De",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.operationFee01,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "operationFee01", $$v);
      },
      expression: "form.operationFee01"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Até",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeStart01,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeStart01", $$v);
      },
      expression: "form.rangeStart01"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Taxa de corretagem",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeEnd01,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeEnd01", $$v);
      },
      expression: "form.rangeEnd01"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "De",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.operationFee02,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "operationFee02", $$v);
      },
      expression: "form.operationFee02"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Até",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeStart02,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeStart02", $$v);
      },
      expression: "form.rangeStart02"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Taxa de corretagem",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeEnd02,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeEnd02", $$v);
      },
      expression: "form.rangeEnd02"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "De",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.operationFee03,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "operationFee03", $$v);
      },
      expression: "form.operationFee03"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Até",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeStart03,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeStart03", $$v);
      },
      expression: "form.rangeStart03"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Taxa de corretagem",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeEnd03,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeEnd03", $$v);
      },
      expression: "form.rangeEnd03"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "De",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.operationFee04,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "operationFee04", $$v);
      },
      expression: "form.operationFee04"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Até",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeStart04,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeStart04", $$v);
      },
      expression: "form.rangeStart04"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Taxa de corretagem",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeEnd04,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeEnd04", $$v);
      },
      expression: "form.rangeEnd04"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "De",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.operationFee05,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "operationFee05", $$v);
      },
      expression: "form.operationFee05"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Até",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeStart05,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeStart05", $$v);
      },
      expression: "form.rangeStart05"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Taxa de corretagem",
      mask: ["# %", "## %", "### %"]
    },
    model: {
      value: _vm.form.rangeEnd05,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rangeEnd05", $$v);
      },
      expression: "form.rangeEnd05"
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;