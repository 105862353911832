"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("@/components");
var _default2 = {
  name: 'ModalWarning',
  components: {
    Modal: _components.Modal
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    idItem: {
      type: [String, Number],
      default: null
    },
    idSecond: {
      type: [String, Number],
      default: null
    },
    action: {
      type: Function,
      default: function _default() {}
    }
  },
  methods: {
    cancel: function cancel() {
      this.$parent.close();
    },
    confirm: function confirm() {
      this.$parent.close();
      this.action(this.idItem, this.idSecond);
    }
  }
};
exports.default = _default2;