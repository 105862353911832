"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.consumersService = void 0;
var _http = require("@/config/http");
var fetchAllLink = function fetchAllLink(status) {
  var params = null;
  if (status) {
    params = {
      Status: status
    };
  }
  return (0, _http.http)({
    url: "/client/businessUnits/assets/all",
    method: 'GET',
    params: params
  });
};
var fetchAllNotLink = function fetchAllNotLink(status) {
  var params = null;
  if (status) {
    params = {
      Status: status
    };
  }
  return (0, _http.http)({
    url: "/client/businessUnits/assets/available",
    method: 'GET',
    params: params
  });
};
var fetchConsumersAuthorizations = function fetchConsumersAuthorizations(status) {
  var params = null;
  if (status) {
    params = {
      Status: status
    };
  }
  return (0, _http.http)({
    url: "/businessUnitDigitalAsset/all",
    method: 'GET',
    params: params
  });
};
var fetchConsumersBusinessUnit = function fetchConsumersBusinessUnit(idBusinessUnit) {
  return (0, _http.http)({
    url: "/client/businessUnits/assets/".concat(idBusinessUnit),
    method: 'GET'
    // params: {
    // Status: 3
    // }
  });
};

var update = function update(payload, id) {
  return (0, _http.http)({
    url: "/client/businessUnitDigitalAsset/".concat(id),
    method: 'PUT',
    data: payload
  });
};
var create = function create(payload) {
  return (0, _http.http)({
    url: "/client/businessUnitDigitalAsset/",
    method: 'POST',
    data: payload
  });
};
var deleteConsumerBusinessUnit = function deleteConsumerBusinessUnit(BUDId) {
  return (0, _http.http)({
    url: "/client/businessUnitDigitalAsset/".concat(BUDId),
    method: 'DELETE'
  });
};
var consumersService = {
  fetchAllNotLink: fetchAllNotLink,
  fetchAllLink: fetchAllLink,
  fetchConsumersAuthorizations: fetchConsumersAuthorizations,
  fetchConsumersBusinessUnit: fetchConsumersBusinessUnit,
  deleteConsumerBusinessUnit: deleteConsumerBusinessUnit,
  update: update,
  create: create
};
exports.consumersService = consumersService;