"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'TableCardCoop',
  props: {
    display: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    redirectTaskDetail: {
      type: Function,
      default: function _default() {}
    }
    // updatePage: { type: Function, default: () => {} },
  },
  data: function data() {
    return {
      current: 0,
      steps: [{
        id: 0,
        label: 'Todas',
        displayed: true
      }, {
        id: 1,
        label: 'Não respondidas',
        displayed: true
      }, {
        id: 2,
        label: 'Respondidas',
        displayed: true
      }, {
        id: 3,
        label: 'Encerradas',
        displayed: true
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    setCurrent: function setCurrent(id) {
      this.current = id;
      this.activeStep = id;
    }
  }
};
exports.default = _default2;