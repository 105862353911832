"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _FormNewService = _interopRequireDefault(require("./components/FormNewService"));
var _FormDocumentService = _interopRequireDefault(require("./components/FormDocumentService"));
var _WarningsText = _interopRequireDefault(require("./components/WarningsText"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _default = {
  name: 'NewServiceForm',
  bodyClass: 'new-service-update-page',
  components: {
    FormNewService: _FormNewService.default,
    WarningsText: _WarningsText.default,
    FormDocumentService: _FormDocumentService.default
  },
  data: function data() {
    return {
      showWarning: false,
      idService: null,
      activeStep: 0,
      steps: [{
        label: 'Dados do serviço'
      }]
    };
  },
  computed: {
    titlePage: function titlePage() {
      if (this.idService) return 'Atualizar serviço';
      return 'Novo serviço';
    }
  },
  mounted: function mounted() {
    var id = this.$route.params.id;
    if (id) {
      this.idService = id;
      this.steps = [{
        label: 'Dados do serviço'
      }, {
        label: 'Documentos'
      }];
    }
    this.fetchAllClients();
    this.fetchServiceTypes();
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)(['fetchAllClients', 'fetchServiceTypes'])), {}, {
    handleClick: function handleClick(val) {
      this.$router.push({
        path: "/founds"
      });
    },
    setTab: function setTab(index) {
      this.activeStep = index;
    }
  })
};
exports.default = _default;