"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'TableTimesDocumentoscopia',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    actionItem: {
      type: Function,
      default: function _default() {}
    }
  }
};
exports.default = _default2;