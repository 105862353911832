"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Adicionar Organização")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("form", {
    attrs: {
      id: "formNewClientPerson"
    }
  }, [_c("app-select", {
    attrs: {
      label: "Organização",
      placeholder: "Selecione o cliente",
      items: _vm.listAllClients,
      option: "_id",
      "name-item": "name"
    },
    model: {
      value: _vm.clientUser,
      callback: function callback($$v) {
        _vm.clientUser = $$v;
      },
      expression: "clientUser"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "content-btns"
  }, [_c("b-button", {
    attrs: {
      type: "is-info"
    },
    on: {
      click: _vm.checkForm
    }
  }, [_vm._v("\n        Salvar\n      ")])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;