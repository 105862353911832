"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-customer-data-form-client"
  }, [_c("form", {
    attrs: {
      id: "customerDataForm"
    },
    on: {
      submit: _vm.saveClient
    }
  }, [_c("card", {
    staticClass: "card-customer-finished-form-prospect-component"
  }, [_c("h1", [_vm._v("Dados do cliente")]), _vm._v(" "), _c("app-select", {
    attrs: {
      placeholder: "Selecione o tipo de cliente",
      label: "Tipo de cliente",
      error: _vm.$v.form.OrganizationType.$error,
      items: _vm.listTypes,
      option: "id",
      "name-item": "name",
      disabled: _vm.isBlocked
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.OrganizationType, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.OrganizationType.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.OrganizationType, "$model", $$v);
      },
      expression: "$v.form.OrganizationType.$model"
    }
  }), _vm._v(" "), _vm.form.OrganizationType ? _c("div", [_vm.isPF ? _c("app-input", {
    attrs: {
      label: "CPF",
      placeholder: "Informe o CPF do cliente",
      mask: ["###.###.###-##"],
      error: _vm.$v.form.Document.$error,
      disabled: _vm.isBlocked
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Document, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Document.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Document, "$model", $$v);
      },
      expression: "$v.form.Document.$model"
    }
  }) : _vm._e(), _vm._v(" "), _vm.isPJ ? _c("app-input", {
    attrs: {
      label: "CNPJ",
      placeholder: "Informe o CNPJ do cliente",
      mask: ["##.###.###/####-##"],
      error: _vm.$v.form.Document.$error,
      disabled: _vm.isBlocked
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Document, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Document.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Document, "$model", $$v);
      },
      expression: "$v.form.Document.$model"
    }
  }) : _vm._e(), _vm._v(" "), _c("app-input", {
    attrs: {
      label: _vm.labelNameClient,
      placeholder: _vm.placeNameClient,
      error: _vm.$v.form.BusinessName.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.BusinessName, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.BusinessName.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.BusinessName, "$model", $$v);
      },
      expression: "$v.form.BusinessName.$model"
    }
  }), _vm._v(" "), _vm.isPJ ? _c("app-input", {
    attrs: {
      label: "Nome Fantasia",
      placeholder: "Informe o nome fantasia",
      error: _vm.$v.form.Name.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Name, "$model", $$v);
      },
      expression: "$v.form.Name.$model"
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "E-mail do cliente",
      placeholder: "Informe o e-mail do cliente",
      error: _vm.$v.form.Email.$error,
      type: "email"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Email, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Email.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Email, "$model", $$v);
      },
      expression: "$v.form.Email.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Confirme o e-mail do cliente",
      placeholder: "Informe a confirmação do e-mail do cliente",
      error: _vm.$v.form.EmailConfirmation.$error,
      type: "email"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.EmailConfirmation, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.EmailConfirmation.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.EmailConfirmation, "$model", $$v);
      },
      expression: "$v.form.EmailConfirmation.$model"
    }
  })], 1)]), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Telefone",
      placeholder: "+00 (00) 00000 - 0000",
      mask: ["+## (##) ##### - ####", "+## (##) #### - ####"],
      error: _vm.$v.form.Phone.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Phone, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Phone.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Phone, "$model", $$v);
      },
      expression: "$v.form.Phone.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      placeholder: "CEP",
      label: "CEP",
      mask: ["##.###-###"],
      error: _vm.$v.form.ZipCode.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.ZipCode, "$error", $event);
      }
    },
    nativeOn: {
      change: function change($event) {
        return _vm.consultZipcode.apply(null, arguments);
      }
    },
    model: {
      value: _vm.$v.form.ZipCode.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.ZipCode, "$model", $$v);
      },
      expression: "$v.form.ZipCode.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      placeholder: "Número do endereço",
      label: "Número do endereço",
      error: _vm.$v.form.AddressNumber.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.AddressNumber, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.AddressNumber.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.AddressNumber, "$model", $$v);
      },
      expression: "$v.form.AddressNumber.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      placeholder: "Logradouro",
      label: "Logradouro",
      error: _vm.$v.form.Address.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Address, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Address.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Address, "$model", $$v);
      },
      expression: "$v.form.Address.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Bairro",
      placeholder: "Informe o bairro da residência",
      error: _vm.$v.form.District.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.District, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.District.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.District, "$model", $$v);
      },
      expression: "$v.form.District.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Cidade",
      placeholder: "Informe a cidade da residência",
      error: _vm.$v.form.City.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.City, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.City.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.City, "$model", $$v);
      },
      expression: "$v.form.City.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "UF",
      placeholder: "Informe a UF da residência",
      mask: ["AA"],
      error: _vm.$v.form.State.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.State, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.State.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.State, "$model", $$v);
      },
      expression: "$v.form.State.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Site da organização",
      placeholder: "Site da organização",
      error: _vm.$v.form.Site.$error,
      type: "url"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Site, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Site.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Site, "$model", $$v);
      },
      expression: "$v.form.Site.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Setor de atuação",
      placeholder: "Setor de atuação",
      error: _vm.$v.form.Setor.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Setor, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Setor.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Setor, "$model", $$v);
      },
      expression: "$v.form.Setor.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Quantidade de funcionários",
      placeholder: "Total de funcionários",
      error: _vm.$v.form.Size.$error,
      mask: ["#####"]
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Size, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Size.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Size, "$model", $$v);
      },
      expression: "$v.form.Size.$model"
    }
  })], 1)]), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Descrição do cliente",
      placeholder: "Descreva brevemente",
      error: _vm.$v.form.Description.$error,
      type: "textarea",
      max: "1500"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Description, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Description.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Description, "$model", $$v);
      },
      expression: "$v .form.Description.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _c("b-field", {
    class: _vm.$v.form.Logo.$error ? "is-danger" : "",
    attrs: {
      label: "Logomarca"
    }
  }, [_c("b-upload", {
    attrs: {
      "drag-drop": "",
      accept: "image/*"
    },
    model: {
      value: _vm.fileLogo,
      callback: function callback($$v) {
        _vm.fileLogo = $$v;
      },
      expression: "fileLogo"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.fileLogo ? _c("p", [_vm._v("Busque ou arraste o arquivo de imagem aqui")]) : _c("p", [_vm._v(_vm._s(_vm.fileLogo.name || "Arquivo selecionado"))])], 1)]), _vm._v(" "), _vm.logoValid && !_vm.fileLogo ? _c("img", {
    attrs: {
      src: _vm.form.Logo,
      alt: "Logotipo"
    }
  }) : _vm._e()])], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-buttons"
  }, [_c("b-button", {
    staticClass: "btn-save-button",
    on: {
      click: _vm.saveClient
    }
  }, [_vm._v("\n        Salvar\n      ")])], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;