"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compositionApi = require("@vue/composition-api");
var _default2 = (0, _compositionApi.defineComponent)({
  name: 'ServicesInactivesCallsTableList',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    statusCode: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      checkedRows: [],
      isModalActive: false,
      trashObject: null,
      perPage: 10
    };
  },
  computed: {
    paginated: function paginated() {
      return this.list.length > this.perPage;
    }
  },
  methods: {
    trashModalOpen: function trashModalOpen(obj) {
      this.trashObject = obj;
      this.isModalActive = true;
    },
    trashConfirm: function trashConfirm() {
      this.isModalActive = false;
      this.$buefy.snackbar.open({
        message: 'Confirmed',
        queue: false
      });
    },
    trashCancel: function trashCancel() {
      this.isModalActive = false;
    }
  }
});
exports.default = _default2;