"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-check form-check-radio",
    class: [_vm.inlineClass, {
      disabled: _vm.disabled
    }]
  }, [_c("label", {
    staticClass: "form-check-label",
    attrs: {
      for: _vm.cbId
    }
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    staticClass: "form-check-input",
    attrs: {
      id: _vm.cbId,
      type: "radio",
      disabled: _vm.disabled
    },
    domProps: {
      value: _vm.label,
      checked: _vm._q(_vm.model, _vm.label)
    },
    on: {
      change: function change($event) {
        _vm.model = _vm.label;
      }
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "form-check-sign"
  }), _vm._v(" "), _vm._t("default")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;