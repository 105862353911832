"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-list-batch-processing-component"
  }, [_c("b-table", {
    attrs: {
      data: _vm.list
    },
    on: {
      click: _vm.actionOpen
    }
  }, [[_c("b-table-column", {
    staticClass: "img-rounded-td",
    attrs: {
      centered: ""
    }
  }, [_c("div", {
    staticClass: "img-display"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icons/google-docs.png")
    }
  })])]), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Data de upload"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(_vm._f("dateTimeWithToday")(props.row.LastModified)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Unidade de négocio"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.BU || "--") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.processed || "Em processamento") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Tamanho"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(_vm._f("bytesToSize")(props.row.Size)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Baixar",
      centered: ""
    }
  }, [_c("b-button", {
    attrs: {
      "icon-right": "download"
    }
  })], 1)]], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;