"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "card-component card"
  }, [_vm.title ? _c("header", {
    staticClass: "card-header"
  }, [_c("p", {
    staticClass: "card-header-title"
  }, [_vm.icon ? _c("b-icon", {
    attrs: {
      icon: _vm.icon,
      "custom-size": "default"
    }
  }) : _vm._e(), _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")], 1), _vm._v(" "), _vm.headerIcon ? _c("a", {
    staticClass: "card-header-icon",
    attrs: {
      href: "#",
      "aria-label": "more options"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.headerIconClick.apply(null, arguments);
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: _vm.headerIcon,
      "custom-size": "default"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_vm._t("default")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;