"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-dash"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Monitor de Conexões")]), _vm._v(" "), _c("card-filter-days", {
    attrs: {
      time: _vm.timeDash,
      times: _vm.times
    },
    on: {
      "update:time": function updateTime($event) {
        _vm.timeDash = $event;
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "columns cols-cards-info is-multiline"
  }, _vm._l(_vm.services, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column is-one-third"
    }, [_c("card-monitor", {
      attrs: {
        title: "",
        service: _vm._f("toUpperCase")(item),
        logo: item.Logo,
        active: item.Active
      },
      nativeOn: {
        click: function click($event) {
          return _vm.openDetailService(item);
        }
      }
    })], 1);
  }), 0)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;