"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("line-chart-element", {
    attrs: {
      "chart-id": _vm.chartId,
      "chart-options": _vm.chartOptions,
      "chart-data": _vm.chartData,
      width: _vm.width,
      height: _vm.height
    }
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;