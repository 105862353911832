"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("@/components");
var _default = {
  name: 'ConsumersProvidersDetail',
  bodyClass: 'consumers-providers-detail-page',
  components: {
    CardCertificate: _components.CardCertificate
  },
  methods: {
    goBack: function goBack() {
      this.$router.back();
    },
    openDetail: function openDetail() {
      this.$router.push({
        path: '/connection-service-detail'
      });
    }
  }
};
exports.default = _default;