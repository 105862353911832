"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'NButton',
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    type: {
      type: String,
      default: 'default'
    },
    nativeType: {
      type: String,
      default: 'button'
    },
    round: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    social: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    classes: function classes() {
      var btnClasses = [{
        'btn-simple': this.simple
      }, {
        'btn-icon': this.icon
      }, {
        'btn-round': this.round
      }, {
        'btn-block': this.block
      }, {
        'btn-social': this.social
      }, {
        'btn-link': this.link
      }, {
        'btn-wd': this.wide
      }, "btn-".concat(this.type)];
      if (this.size) {
        btnClasses.push("btn-".concat(this.size));
      }
      return btnClasses;
    }
  },
  methods: {
    handleClick: function handleClick(evt) {
      this.$emit('click', evt);
    }
  }
};
exports.default = _default;