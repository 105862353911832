"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
require("@/assets/styles/index.scss");
var _vue = _interopRequireDefault(require("vue"));
var _App = _interopRequireDefault(require("./App"));
var _i18n = _interopRequireDefault(require("./i18n"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _moment = _interopRequireDefault(require("moment"));
var _pluginsKit = _interopRequireDefault(require("./plugins/plugins-kit"));
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/*!
 =========================================================
 * HivePlace BackOffice - v1.0.0
 =========================================================
 * Product Page: https://backoffice.hiveplace.com.br
 * Copyright 2020 Skill
 * Designed by www.skilltecnologia.com Coded by skilltecnologia.com.br
 =========================================================
 */
/* Styles */

// Libs

// permission control

// global filters
// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});

// ProductionTip
_vue.default.config.productionTip = false;

// Plugins
_vue.default.use(_pluginsKit.default);

// translate date format
_moment.default.locale('pt-BR');
new _vue.default({
  el: '#app',
  i18n: _i18n.default,
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});