"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-field", {
    class: "itj-input-component ".concat(_vm.classField),
    attrs: {
      message: "",
      type: _vm.error ? "is-danger" : "",
      label: _vm.included && !_vm.computedValue ? "" : _vm.label,
      expanded: _vm.expanded
    }
  }, [_vm.typeInput === "money" ? _c("div", {
    staticClass: "control",
    class: {
      empty: !_vm.computedValue
    }
  }, [_c("money", _vm._b({
    ref: "input",
    class: "input ".concat(_vm.classInput, " ").concat(_vm.error ? "is-danger" : ""),
    attrs: {
      autocomplete: _vm.complete,
      placeholder: _vm.placeholder,
      mask: _vm.mask,
      disabled: _vm.disabled,
      minlength: _vm.min,
      maxlength: _vm.max,
      masked: _vm.masked
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  }, "money", _vm.money, false))], 1) : _vm._e(), _vm._v(" "), _vm.mask.length && _vm.typeInput !== "money" ? _c("div", {
    staticClass: "control",
    class: {
      empty: !_vm.computedValue
    }
  }, [_c("the-mask", _vm._b({
    ref: "input",
    class: "input ".concat(_vm.classInput, " ").concat(_vm.error ? "is-danger" : ""),
    attrs: {
      autocomplete: _vm.complete,
      placeholder: _vm.placeholder,
      mask: _vm.mask,
      disabled: _vm.disabled,
      minlength: _vm.min,
      maxlength: _vm.max,
      masked: _vm.masked,
      name: _vm.name
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  }, "the-mask", _vm.$attrs, false))], 1) : _vm._e(), _vm._v(" "), !_vm.mask.length && _vm.typeInput !== "money" ? _c("b-input", {
    class: _objectSpread({
      empty: !_vm.computedValue
    }, _vm.classInput),
    attrs: {
      autocomplete: _vm.complete,
      placeholder: _vm.placeholder,
      type: _vm.type,
      disabled: _vm.disabled,
      minlength: _vm.min,
      maxlength: _vm.max,
      name: _vm.name,
      "has-counter": true
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  }) : _vm._e(), _vm._v(" "), _vm._t("default"), _vm._v(" "), _vm.description ? _c("span", {
    staticClass: "item-description-field"
  }, [_vm._v("\n    " + _vm._s(_vm.description) + "\n  ")]) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;